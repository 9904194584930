import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "../../pages/home/HomePage";
import { NewPage } from "../../pages/new/NewPage";
import { IndexPage } from "../../pages/index/IndexPage";
import { PrivacyPolicyPage } from "../../pages/doc/PrivacyPolicyPage";
import { TermsOfUsePage } from "../../pages/doc/TermsOfUsePage";
import { TermsOfPaymentAndRefundsPage } from "../../pages/doc/TermsOfPaymentAndRefundsPage";
import { PersonalDataProcessingAgreementPage } from "../../pages/doc/PersonalDataProcessingAgreementPage";
import { CookiesPolicyPage } from "../../pages/doc/CookiesPolicyPage";

export function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/new" element={<IndexPage />} />
        <Route path="/doc/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/doc/terms-of-use" element={<TermsOfUsePage />} />
        <Route path="/doc/terms-of-payment-and-refunds" element={<TermsOfPaymentAndRefundsPage />} />
        <Route path="/doc/personal-data-processing-agreement" element={<PersonalDataProcessingAgreementPage />} />
        <Route path="/doc/cookies-policy" element={<CookiesPolicyPage />} />
        <Route path="*" element={<NewPage />} />
      </Routes>
    </BrowserRouter>
  );
}
