import React from "react";
import { Button, Center, GridItem, Heading, Image, SimpleGrid, Text } from "@chakra-ui/react";
import "./IndexPageBlock5.scss";

interface IIndexPageBlock5 {
  onScrollToClick: () => void;
}

const blocksData = [
  {
    id: 1,
    title: "Training and immersion in management automation",
    description:
      "You go through 4 weeks of digitisation and automation of your business management. You develop a functional organizational structure of the company and write out functions, and tasks for each employee. Describe key indicators and results for each function. Establish a framework so that the company is systematic, predictable and manageable.",
  },
  {
    id: 2,
    title: "Implement an Automated Operations Director into your business",
    description:
      "Our consultant helps you digitize your company and implement everything developed during the training into the platform. As a result, you put people in charge of functions and assign tasks. Our Task Driver monitors 100% task completion and approvals by your employees.",
  },
  {
    id: 3,
    title: "Join a digital club to maintain the system",
    description:
      "Be surrounded by like-minded people and digital entrepreneurs. Grow the company, strategise and scale the company, free from operational management.",
  },
];

export function IndexPageBlock5(props: IIndexPageBlock5) {
  return (
    <div className="indexPageBlock5 __block-wrapper">
      <div className="__block-container">
        <Center>
          <Heading textAlign="center" as="h1" fontWeight={900} style={{ padding: "105px 0" }}>
            How does it work?
          </Heading>
        </Center>
        <SimpleGrid marginBottom="105px" spacing={10} minChildWidth={300}>
          {blocksData.map((data) => (
            <GridItem key={data.id}>
              <div className="d-flex flex-column">
                <div className="d-flex align-center">
                  <Image marginRight="16px" boxSize="96px" src={`/images/index_page/block_5/${data.id}.svg`} />
                  <Heading as="h2" fontSize="20px">
                    {data.title}
                  </Heading>
                </div>
                <Text marginTop="16px">{data.description}</Text>
              </div>
            </GridItem>
          ))}
        </SimpleGrid>
        <Center marginBottom="65px">
          <Button colorScheme="red" height={65} fontSize="20px" onClick={props.onScrollToClick}>
            Choose
          </Button>
        </Center>
      </div>
    </div>
  );
}
