import React from "react";
import { Button, Heading, Image } from "@chakra-ui/react";
import "./NewPageBlock1.scss";

interface ILandingPageBlock1 {
  onScrollToClick: () => void;
  onScrollToContactsClick: () => void;
}

export function NewPageBlock1(props: ILandingPageBlock1) {
  return (
    <div className="landingPageBlock1 __block-wrapper">
      <div className="__block-container full-height">
        <div className="full-height">
          <Image src="/images/logo-sign.png" />
          <Heading as="h5" fontSize="16px" className="pl-10">
            24/7 automated operations director
          </Heading>
        </div>
        <div className="d-stack-row spacing-10">
          <Button size="lg" variant="link" onClick={props.onScrollToContactsClick}>
            Contacts
          </Button>
          <Button size="lg" colorScheme="red" onClick={props.onScrollToClick}>
            Choose a tariff
          </Button>
        </div>
      </div>
    </div>
  );
}
