import React, { useState } from "react";
import "./NewPage.scss";
import { NewPageBuyDialog } from "../../modules/pages/newPage/newPageBuyDialog/NewPageBuyDialog";
import { NewPageBlock1 } from "../../modules/pages/newPage/newPageBlock1/NewPageBlock1";
import { NewPageBlock2 } from "../../modules/pages/newPage/newPageBlock2/NewPageBlock2";
import { NewPageBlock3 } from "../../modules/pages/newPage/newPageBlock3/NewPageBlock3";
import { NewPageBlock4 } from "../../modules/pages/newPage/newPageBlock4/NewPageBlock4";
import { NewPageBlock5 } from "../../modules/pages/newPage/newPageBlock5/NewPageBlock5";
import { NewPageBlock6 } from "../../modules/pages/newPage/newPageBlock6/NewPageBlock6";
import { NewPageBlock7 } from "../../modules/pages/newPage/newPageBlock7/NewPageBlock7";
import { NewPageBlock8 } from "../../modules/pages/newPage/newPageBlock8/NewPageBlock8";
import { NewPageBlock9 } from "../../modules/pages/newPage/newPageBlock9/NewPageBlock9";
import { NewPageBlock10 } from "../../modules/pages/newPage/newPageBlock10/NewPageBlock10";
import { NewPageBlock11 } from "../../modules/pages/newPage/newPageBlock11/NewPageBlock11";
import { NewPageBlock12 } from "../../modules/pages/newPage/newPageBlock12/NewPageBlock12";
import { NewPageBlock13 } from "../../modules/pages/newPage/newPageBlock13/NewPageBlock13";

export type TBuyDialog = "standard" | "premium" | "vip" | null;

export function NewPage() {
  const [isBuyDialogOpen, setIsBuyDialogOpen] = useState<TBuyDialog>(null);

  const handleBuyDialogOpen = (type: TBuyDialog) => {
    setIsBuyDialogOpen(type);
  };

  const handleBuyDialogClose = () => {
    setIsBuyDialogOpen(null);
  };

  const handleScrollToContactsScrollRef = () => {
    const section = document.querySelector("#indexPageContactsBlockToScroll");
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleScrollToScrollRef = () => {
    const section = document.querySelector("#indexPageBlockToScroll");
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="newPage">
      <NewPageBuyDialog isOpen={isBuyDialogOpen != null} type={isBuyDialogOpen} onClose={handleBuyDialogClose} />
      <NewPageBlock1
        onScrollToClick={handleScrollToScrollRef}
        onScrollToContactsClick={handleScrollToContactsScrollRef}
      />
      <NewPageBlock2 onScrollToClick={handleScrollToScrollRef} />
      <NewPageBlock3 />
      <NewPageBlock4 />
      <NewPageBlock5 onScrollToClick={handleScrollToScrollRef} />
      <NewPageBlock6 onScrollToClick={handleScrollToScrollRef} />
      <NewPageBlock7 />
      <NewPageBlock8 />
      <NewPageBlock9 onBuyClick={handleBuyDialogOpen} />
      <NewPageBlock10 />
      <NewPageBlock11 />
      <NewPageBlock12 onScrollToClick={handleScrollToScrollRef} />
      <NewPageBlock13 />
    </div>
  );
}
