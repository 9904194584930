import React from "react";
import "./DocPage.scss";

export const TermsOfPaymentAndRefundsPage = () => {
  return (
    <div className="document__wrapper">
      <h1>TERMS OF PAYMENT AND REFUNDS</h1>
      <h3>These Terms were last updated on October 01, 2023</h3>
      <p>
        Visotsky Consulting Inc. (hereinafter referred to as "Visotsky", "We", "Us" or "Our", “Company”) keep all of our
        products highly reputable, and your satisfaction is very important to us. Any purchases made from us (including
        all relevant website properties, telephone sales and live on-site purchases) are subject to the following terms
        and conditions.
      </p>

      <h2>General Terms and Conditions</h2>
      <p>
        Privacy Policy. Please review our{" "}
        <a target="_blank" href="/doc/privacy-policy">
          Privacy Policy
        </a>
        , which also governs your visit to our website and any purchases made on it.
      </p>

      <h2>There is no guarantee of results.</h2>
      <p>
        We do not guarantee that you will achieve any particular financial, managerial, marketing or other results,
        generate any particular amount of income or achieve any particular goal. By using the information you obtain on
        our Website and from our products, you do so at your own discretion and are solely responsible for any decisions
        and actions resulting from your decision to use such information. We do not provide private legal, investment,
        professional, tax or financial advice. All decisions you make as a result of receiving services from Visotsky
        Consulting Inc. are your own and you are solely responsible for them.
      </p>

      <h2>Visotsky, Business Booster programs</h2>
      <p>
        You will receive access to the training platform for the term specified in the description of the product you
        purchased. If you have problems accessing the purchased product or service, please contact our customer support
        department immediately at <a href="tel:+13476040113" children="+1 347 604 0113" /> between 9:00 a.m. and 6:00
        p.m. Eastern Standard Time, Monday through Friday, so that we can resolve the problem. The purchase of a Visosky
        training module does not authorize the purchaser to share, reproduce, or resell the product in any way. Digital
        access is considered fully delivered when an email is sent to the email address used to subscribe to the
        Service. If you do not receive the digital access link within 5 calendar days of accepting the Terms of Use, you
        must send an email to <a href="mailto:support@visotsky.us" children="support@visotsky.us" /> to notify Company
        that you did not receive the link. If you do not send an email notifying the Company that you did not receive
        the link and the email is delivered to your email address, the digital products or Services shall be deemed
        fully delivered.
      </p>
      <p>
        If you have subscribed to Visotsky's customized monthly training access program, to cancel your subscription:
        email us at: <a href="mailto:support@visotsky.us" children="support@visotsky.us" />. Please note that canceling
        a monthly subscription will take effect in the month in which you cancel. No refunds will be given regardless of
        product or Service usage.
      </p>
      <p>
        All sales of digital and downloadable products are final. We do not issue refunds for digital products. Once a
        download/digital access has been provided to you, we can no longer refund anything.
      </p>
      <p>
        Please note that any and all agreements for downloadable/digital products, Visotsky digital products are NOT
        subject to cancellation, refund or credit from Visotsky except as set forth in these Terms. We do not accept any
        verbal modification of these agreements and strictly adhere to the "no refunds and cancellation" policy set
        forth in these Terms. AFTER the full term of your agreement has expired, you may request to cancel the automatic
        renewal of the Terms, otherwise your agreement will be converted to a monthly fee agreement. Any cancellations
        sent before the full agreement term expires will cancel the automatic renewal only, and your monthly bills will
        still be due until the entire agreement fee is paid in full. There are NO refunds for digital products or
        additional Services. By remaining on the Website or purchasing our Services, you agree to all of the{" "}
        <a target="_blank" href="/doc/terms-of-use">
          Terms of Use
        </a>{" "}
        and these Terms, including you agree not to make refunds for any Services rendered or products delivered. You
        also agree and acknowledge that you pay in monthly installments of the total contract price as a payment plan
        rather than a regular subscription fee.
      </p>
      <p>
        Canceling or deleting a registered credit card does not cancel your subscription and violates the terms of the
        agreement. If at any time you become delinquent on an invoice for 90 days or if the invoice becomes delinquent
        for three consecutive payments, your account will be suspended and you will remain liable for the full contract
        price for the term of the agreement.
      </p>
      <p>
        Visotsky may refuse to provide the Services at any time prior to granting you access to the product, without
        cause, and refund your payment for the Services, subject to the terms and conditions set forth in the written
        agreement.
      </p>
      <p>
        The period of access to the Services and products corresponds to the payment period. If you fail to fulfill your
        payment obligation, Visotsky will terminate your access to the products and Services. In this case, no refund of
        previously paid funds will be made - they are payment for the access actually provided for the relevant period.
      </p>
      <p>
        If you have problems accessing a purchased digital product, please contact our customer support department
        immediately at <a href="tel:+13476040113" children="+1 347 604 0113" /> between 9:00 a.m. and 6:00 p.m. Eastern
        Standard Time, Monday through Friday, so that we can resolve the problem. The purchase of a digital product does
        not authorize the purchaser to share, reproduce or resell the product in any way.
      </p>
      <p>
        When you pay for our Services on the Website or based on an email notification you receive, you will
        automatically be redirected to a payment acceptance system page to make payment. We do not control the hardware
        and software of the electronic payment system. If your funds are debited as a result of such errors, but the
        payment was not authorized by the electronic payment system, the electronic payment system provider is
        responsible for refunding your funds.
      </p>
      <p>
        You may, during the program period, subject to payment for three (3) months or more, enjoy the right to suspend
        Services for a period not exceeding three (3) months per year upon prior written notice, which must be sent to
        the following email address: ________________.
      </p>
      <p>
        If you violate these Terms or the Terms of Use, Visotsky has the right to terminate your access to your Personal
        Account, as well as block your access to the Platform or to a particular Service without any refund of funds
        paid by you. The funds paid to you in such a case shall be deemed to be our compensation for preparing for the
        provision of the Services and organizing the provision of access.
      </p>

      <h2>Terms of refund for the Services</h2>
      <p>Refunds can only be made within 21 days of receipt of payment by Visotsky from you for the Services.</p>
      <p>
        We undertake to contact you within no more than 5 working days after receipt of your request to organize the
        refund procedure. In doing so, you undertake to give full details of the reasons for the decision to make a
        refund in order to improve the quality and perfection of our Services.
      </p>
      <p>
        The refund for the services shall be made within 45 (Forty-five) business days from the date of receipt by
        Visotsky of your justified request.
      </p>
      <p>
        Upon receipt of a request for a refund, Visotsky has the right to suspend or block access to the Platform,
        Account, social networks, including various closed groups, forums and pages with restricted access related to
        the provision of the Services from the date of commencement of the verification of your request as to whether or
        not there are grounds for a refund.
      </p>

      <h2>Payment Plans</h2>
      <p>
        If a product or Service is purchased using a monthly payment plan, the customer is responsible for 100% of the
        agreed upon payments equal to the original purchase price of the product or Service. If any payments are not
        received, the entire purchase is canceled and payments made up to that point are canceled and non-refundable.
        Access to any digital product or Platform will be denied.
      </p>

      <h2>COVID-19, live broadcasts and interactive program components</h2>
      <p>
        We comply with all CDC guidelines and all local, state and federal government regulations to ensure a safe
        environment for event attendees. We also take precautions such as temperature checks, adherence to social
        distancing rules, etc. However, this is not a guarantee or promise of a virus free environment. We will follow
        venue rules, which may include stricter masking rules that we must follow in order to host the event. If
        necessary, we may convert the event into a virtual experience.
      </p>

      <h2>OFFERS, PROMOTIONS, BONUSES AND GIFTS ON PURCHASE</h2>
      <p>Offers and discounts do not add up.</p>
      <p>
        We reserve the right to offer discounts on our products. We also reserve the right to cancel and terminate any
        Service if you violate the{" "}
        <a target="_blank" href="/doc/terms-of-use">
          Terms of Use
        </a>{" "}
        or these Terms.
      </p>

      <h2>Account Status</h2>
      <p>
        If your account is in arrears or is not in good standing for any reason, any special offers, flash sales, deals,
        bonuses, purchase gifts, coupons, discounts and incentives are not available for use.
      </p>
    </div>
  );
};
