import React from "react";
// Connect Inter Font
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
// Connect global styles
import "./styles/global.scss";
import { ChakraProvider, extendTheme, theme as chakraTheme } from "@chakra-ui/react";
import { AppRouter } from "./components/service/appRouter/AppRouter";

const theme = extendTheme({
  fonts: {
    ...chakraTheme.fonts,
    body: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    heading: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <AppRouter />
      </div>
    </ChakraProvider>
  );
}

export default App;
