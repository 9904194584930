import React from "react";
import "./IndexPageBlock13.scss";
import { Text } from "@chakra-ui/react";

export function IndexPageBlock13() {
  return (
    <div className="indexPageBlock13 __block-wrapper">
      <div className="__block-container">
        <Text fontSize="20px">All rights reserved, 2021 - {new Date().getFullYear()}.</Text>
      </div>
    </div>
  );
}
