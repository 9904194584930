import React from "react";
import "./DocPage.scss";

export const PersonalDataProcessingAgreementPage = () => {
  return (
    <div className="document__wrapper">
      <h1>Consent to Personal Data Processing (Personal Data Processing Agreement)</h1>
      <p>
        By continuing to use this Website (hereinafter referred to as the "Website"), you agree to voluntarily and of
        your own free will, subject to the Policy on Processing and Protection of Users' Personal Data (available by
        clicking here) (the "Policy"), grant Visotsky Consulting Inc, 368 9th Ave 6FL New York, NY 10001 (us, the
        personal data controller), the right to carry out processing (various actions or a combination of actions) in
        relation to your personal data, including collection, recording, systematization, accumulation, storage,
        clarification, retrieval, use, transfer, depersonalization, blocking, storage and processing of your personal
        data.
      </p>

      <h2>This consent to the transfer of Personal Data about you, including:</h2>
      <p>
        - Your Personal Data: surname, first name, patronymic, address, number of the main identity document and
        information on its issuance;
      </p>
      <p>
        - Personal Data of your representative (if any): surname, name, patronymic, address, number of the identity
        document of the representative, as well as information on its issuance. If you have a power of attorney - its
        details or another document confirming the representative's authority (if you have your consent);
      </p>
      <p>
        - Contact information: phone number, e-mail address, mailing address, messaging accounts, links to social
        networks, as well as your image (photo) and other information transmitted automatically in anonymized form
        through the software.
      </p>

      <h2>You also agree to</h2>
      <p>
        1. We may process your personal data in a variety of ways, including automated processing, non-automated
        processing, mixed processing and other methods appropriate for the purposes of collecting and processing
        personal data as set out in this consent.
      </p>
      <p>
        2. The purpose of collecting and processing your personal data is to ensure the correct organization and
        maintenance of the relationship between us within the civil, administrative and tax areas. This also includes
        relations in the fields of accounting, tax and statistical accounting, as well as the preparation of
        statistical, administrative and other reporting information required by law. Data processing also aims to ensure
        the fulfillment of obligations between us, including financial obligations arising from our contract, and to
        ensure effective communication in the performance of that contract.
      </p>
      <p>
        3. The transfer of your personal data to other legal entities is also for the purpose of ensuring the
        fulfillment of the contract concluded between us.
      </p>
      <p>
        Your consent to the processing of personal data is deemed to have been obtained and becomes effective when you
        complete your registration on the Website using the application form you have provided. Registration is deemed
        completed when you click on the button located under the last prompt question of the application. Your consent
        will remain in effect indefinitely until you revoke it. However, even after revocation, we may continue to
        process your data where and in the manner set out in our policy and applicable law.
      </p>
      <p>
        4. If you choose to withdraw your consent, you may do so at any time by sending written notice of withdrawal by
        email to: <a href="mailto:support@visotsky.us" children="support@visotsky.us" />. Please be aware that even
        after revocation, we may continue to process your data in accordance with policy and law.
      </p>
      <p>
        We confirm that we have read the information about the processing of your Personal Data, including the
        information provided in our{" "}
        <a target="_blank" href="/doc/privacy-policy">
          Privacy Policy
        </a>
        .
      </p>
      <p>
        5. You authorize us to share your personal data with third parties of our choice for the purpose of them
        providing any services to you.
      </p>
      <p>
        6. By confirming your consent to the processing of personal data, you explicitly confirm that you consent to the
        transfer of your personal data in accordance with clause 3 of this Consent. This also includes consent to the
        processing of the data for the purposes described in paragraph 2 of this Consent and using the methods set out
        above and in accordance with the Policy, including processing without the use of automated means.
      </p>
      <p>
        7. The data subject also agrees to receive newsletters and promotional materials from us, as well as from third
        parties on our behalf. These materials may be sent to the e-mail address and contact phone number provided by
        you when registering on the Website, Platform and/or in Account, as well as when completing the Application.
      </p>
      <p>
        8. In addition, you express your consent to the transfer of your Personal Data outside the country to the
        territory of foreign countries, including those that do not provide a level of adequate protection of the rights
        of personal data subjects.
      </p>
    </div>
  );
};
