import React from "react";
import { Button, Heading, Image, Text } from "@chakra-ui/react";
import "./IndexPageBlock12.scss";

interface IIndexPageBlock12 {
  onScrollToClick: () => void;
}

export function IndexPageBlock12(props: IIndexPageBlock12) {
  return (
    <div className="indexPageBlock12 __block-wrapper" style={{ background: "var(--color-tertiary)" }}>
      <div className="__block-container">
        <Heading as="h2" fontSize="24px" fontWeight={900} style={{ maxWidth: "530px" }}>
          Digitize your company's operational management with the Tonnus platform and scale your business 2-10 times
          over the next year.
        </Heading>
        <Text fontSize="16px" style={{ margin: "36px 0", maxWidth: "530px" }}>
          We provide each customer with Task Driver, so we can connect as many as 30 companies to the platform on
          special terms. Hurry up and book your place in the best conditions.
        </Text>
        <Image className="__image" src="/images/index_page/block_12/1.png" />
        <Button colorScheme="red" height={65} fontSize="20px" onClick={props.onScrollToClick}>
          Choose
        </Button>
      </div>
    </div>
  );
}
