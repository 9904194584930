import React from "react";
import { Box, Center, Flex, Heading, Image, Text } from "@chakra-ui/react";
import "./NewPageBlock3.scss";

const blocksData = [
  {
    id: 1,
    imageStyle: {
      margin: "-20px 0 0 0",
    },
    title: "You have to remember all the tasks and personally supervise everything",
    description:
      "You delegate tasks to your employees via messages, email or verbal communications. As a result, you have to keep everything in your head and remind your employees several times to make sure they don't forget important tasks. This takes up a lot of your time and energy.",
  },
  {
    id: 2,
    imageStyle: {
      margin: "0",
    },
    title: "Training and onboarding waste a lot of time and energy",
    description:
      "You train new employees, repeating the same thing like a parrot and wasting time and energy. And if you delegate it to a manager, he also spends a lot of time and parrots on it. And when you hire a new employee, you have to train him or her all over again.",
  },
  {
    id: 3,
    imageStyle: {
      margin: "0",
    },
    title: "Employees don't last for too long",
    description:
      "Competent employees want to work in an environment with organized orders, goals and performance-based appraisal. But instead, organizational chaos sets in and employees leave. You lose valuable staff, start looking for new ones, and so on in a circle.",
  },
  {
    id: 4,
    imageStyle: {
      margin: "-10px 0 0 0",
    },
    title: "Stress, loss of interest and reluctance to do business",
    description:
      "In the beginning, there weren't many tasks, and you did everything with your own hands. But as the business grew, so did the number of employees you had to start supervising. They come to you with problems all the time. Controlling and solving small problems is not the most interesting job for an owner. Over time, you get more and more stressed, and eventually you burn out.",
  },
  {
    id: 5,
    imageStyle: {
      margin: "0",
    },
    title: "Not enough time for yourself and the development of the company",
    description:
      "A large part of your time is taken up by operational tasks, kicking employees around, putting out fires, and finishing work for employees. You do not have any time to develop the company, you do not always have time for yourself, and when you have the time, you no longer have the energy and strength.",
  },
  {
    id: 6,
    imageStyle: {
      margin: "5px 0 0 0",
    },
    title: "Your company develops slowly and gradually degrades",
    description:
      "You are increasingly watching other players develop; while your clients go to them. You are already desperate to introduce something new into the company because the employees don't support it, resulting in more problems.",
  },
];

export function NewPageBlock3() {
  return (
    <div className="indexPageBlock3 __block-wrapper">
      <Center>
        <Heading
          as="h4"
          fontSize="32px"
          textAlign="center"
          color="white"
          style={{ padding: "105px 0px" }}
          maxWidth="800px"
        >
          Without using Tonnus business owners face the situations:
        </Heading>
      </Center>

      <Flex flexWrap="wrap" justifyContent="space-between" maxWidth="1170px" margin="auto">
        {blocksData.map((data) => (
          <Box
            key={data.id}
            flex="0 1 560px"
            display="flex"
            paddingBottom="60px"
            // marginBottom="60px"
            margin="0 auto"
          >
            <Image
              boxSize={100}
              width={100}
              flexShrink={0}
              margin={data.imageStyle.margin}
              src={`/images/index_page/block_3/${data.id}.svg`}
            />
            <Box marginLeft="24px">
              <Heading as="h3" fontSize="20px" color="white" className="mb-3">
                {data.title}
              </Heading>
              <Text fontSize="15px" color="white">
                {data.description}
              </Text>
            </Box>
          </Box>
        ))}
      </Flex>
    </div>
  );
}
