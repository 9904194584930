import React from "react";
import { Center, Heading, List, ListIcon, ListItem } from "@chakra-ui/react";
import "./IndexPageBlock8.scss";
import { FiCheck } from "react-icons/fi";

export function IndexPageBlock8() {
  return (
    <div
      className="indexPageBlock8 __block-wrapper"
      style={{
        background: "var(--color-tertiary)",
        paddingTop: "105px",
        paddingBottom: "105px",
      }}
    >
      <div className="__block-container">
        <Center>
          <Heading fontSize="32px" as="h2" textAlign="center">
            We offer comprehensive implementation programs for all new
            customers.
            <br />
            We’ll be with you every step of the way to make sure you’re
            successful.
          </Heading>
        </Center>
        <Center margin="32px 0 64px 0">
          <Heading fontSize="32px" as="h2" textAlign="center" fontWeight="900">
            Which implementation option is most suitable for you?
          </Heading>
        </Center>
        <div className="__cards-wrapper">
          <div className="__card">
            <div
              className="__header"
              style={{ background: "var(--color-primary)" }}
            >
              <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                Standard
              </span>
              <span style={{ fontSize: "16px" }}>
                Fast implementation in a group format
              </span>
              <span className="__period">6 weeks</span>
            </div>
            <div className="__info __empty">No info</div>
            <div className="__list-wrapper">
              <List>
                <ListItem className="my-3">
                  <ListIcon color="var(--color-primary)" as={FiCheck} />
                  Basic Tonnus implementation program in a group format
                </ListItem>
                <ListItem className="my-3">
                  <ListIcon color="var(--color-primary)" as={FiCheck} />
                  Org chart development in "as it is" format - 1 day of group
                  work
                </ListItem>
                <ListItem className="my-3">
                  <ListIcon color="var(--color-primary)" as={FiCheck} />
                  Independent description of tasks and routines
                </ListItem>
                <ListItem className="my-3">
                  <ListIcon color="var(--color-primary)" as={FiCheck} />
                  Сonnecting to the system and training employees
                </ListItem>
              </List>
            </div>
          </div>

          <div className="__card">
            <div className="__header" style={{ background: "red" }}>
              <span
                style={{ fontWeight: "bold", fontSize: "20px", color: "white" }}
              >
                Premium
              </span>
              <span style={{ fontSize: "16px", color: "white" }}>
                Consulting, deeper systematization, better outcomes
              </span>
              <span className="__period">12 weeks</span>
            </div>
            <div className="__info">Additionally to the Standard rate</div>
            <div className="__list-wrapper">
              <List>
                <ListItem className="my-3">
                  <ListIcon color="var(--color-primary)" as={FiCheck} />
                  Tonnus implementation with consultant support
                </ListItem>
                <ListItem className="my-3">
                  <ListIcon color="var(--color-primary)" as={FiCheck} />
                  Development of Org Chart for company development - 6 days
                  personal work with a consultant
                </ListItem>
                <ListItem className="my-3">
                  <ListIcon color="var(--color-primary)" as={FiCheck} />
                  Business model (elaboration of profitability, financial model,
                  growth points and development strategy)
                </ListItem>
              </List>
            </div>
          </div>

          <div className="__card">
            <div className="__header" style={{ background: "black" }}>
              <span
                style={{ fontWeight: "bold", fontSize: "20px", color: "white" }}
              >
                VIP
              </span>
              <span style={{ fontSize: "16px", color: "white" }}>Turnkey</span>
              <span className="__period">20 weeks</span>
            </div>
            <div className="__info">Additionally to the Premium rate</div>
            <div className="__list-wrapper">
              <List>
                <ListItem className="my-3">
                  <ListIcon color="var(--color-primary)" as={FiCheck} />
                  Implementing Tonnus with our hands and minimal owner
                  involvement
                </ListItem>
              </List>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
