import React, { useState } from "react";
import "./IndexPage.scss";
import { IndexPageBuyDialog } from "../../modules/pages/indexPage/indexPageBuyDialog/IndexPageBuyDialog";
import { IndexPageBlock1 } from "../../modules/pages/indexPage/indexPageBlock1/IndexPageBlock1";
import { IndexPageBlock2 } from "../../modules/pages/indexPage/indexPageBlock2/IndexPageBlock2";
import { IndexPageBlock3 } from "../../modules/pages/indexPage/indexPageBlock3/IndexPageBlock3";
import { IndexPageBlock4 } from "../../modules/pages/indexPage/indexPageBlock4/IndexPageBlock4";
import { IndexPageBlock5 } from "../../modules/pages/indexPage/indexPageBlock5/IndexPageBlock5";
import { IndexPageBlock6 } from "../../modules/pages/indexPage/indexPageBlock6/IndexPageBlock6";
import { IndexPageBlock7 } from "../../modules/pages/indexPage/indexPageBlock7/IndexPageBlock7";
import { IndexPageBlock8 } from "../../modules/pages/indexPage/indexPageBlock8/IndexPageBlock8";
import { IndexPageBlock9 } from "../../modules/pages/indexPage/indexPageBlock9/IndexPageBlock9";
import { IndexPageBlock10 } from "../../modules/pages/indexPage/indexPageBlock10/IndexPageBlock10";
import { IndexPageBlock11 } from "../../modules/pages/indexPage/indexPageBlock11/IndexPageBlock11";
import { IndexPageBlock12 } from "../../modules/pages/indexPage/indexPageBlock12/IndexPageBlock12";
import { IndexPageBlock13 } from "../../modules/pages/indexPage/indexPageBlock13/IndexPageBlock13";

export type TBuyDialog = "standard" | "premium" | "vip" | null;

export function IndexPage() {
  const [isBuyDialogOpen, setIsBuyDialogOpen] = useState<TBuyDialog>(null);

  const handleBuyDialogOpen = (type: TBuyDialog) => {
    setIsBuyDialogOpen(type);
  };

  const handleBuyDialogClose = () => {
    setIsBuyDialogOpen(null);
  };

  const handleScrollToScrollRef = () => {
    const section = document.querySelector("#indexPageBlockToScroll");
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="indexPage">
      <IndexPageBuyDialog isOpen={isBuyDialogOpen != null} type={isBuyDialogOpen} onClose={handleBuyDialogClose} />
      <IndexPageBlock1 />
      <IndexPageBlock2 onScrollToClick={handleScrollToScrollRef} />
      <IndexPageBlock3 />
      <IndexPageBlock4 />
      <IndexPageBlock5 onScrollToClick={handleScrollToScrollRef} />
      <IndexPageBlock6 onScrollToClick={handleScrollToScrollRef} />
      <IndexPageBlock7 />
      <IndexPageBlock8 />
      <IndexPageBlock9 onBuyClick={handleBuyDialogOpen} />
      <IndexPageBlock10 />
      <IndexPageBlock11 />
      <IndexPageBlock12 onScrollToClick={handleScrollToScrollRef} />
      <IndexPageBlock13 />
    </div>
  );
}
