import React from "react";
import "./IndexPageBlock6.scss";
import { Button, Center, Heading, Image, Text } from "@chakra-ui/react";

interface IIndexPageBlock6 {
  onScrollToClick: () => void;
}

const blocksData1 = [
  {
    id: 1,
    title: "Company organizational structure",
    description: "Simple and easy to set up your company's organizational structure.",
  },
  {
    id: 2,
    title: "Proof of task completion",
    description:
      "For any task, you can make it mandatory to provide proof of completion. It can be a photo, a link or just text.",
  },
  {
    id: 3,
    title: "Reports",
    description:
      "With a single click, you can see the status of each task, for each employee. Number of overdue, rescheduled, accepted/not accepted, taken on tasks.",
  },
  {
    id: 4,
    title: "Employee training",
    description:
      "The program of adaptation and training of new employees can be completely automated, by creating a sequence of tasks (lessons) that need to be done by the employee, then checked and thereby integrated.",
  },
  {
    id: 5,
    title: "Chat support and knowledge base",
    description:
      "Our helpdesk is ready to help you with any questions you may have. Our database consists of a wide range of information for the most common issues.",
  },
];

const blocksData2 = [
  {
    id: 1,
    title: "Recurring Tasks Panel",
    description:
      "Set up any of your recurring tasks, including details of who is in charge, the frequency of the task, the deadline, a description of the task, and the outcome of the task.",
  },
  {
    id: 2,
    title: "Manage Tasks",
    description:
      "Tasks are conveniently categorized in a kanban board by statuses. You can see all tasks, messages and comments, both yours and your employees.",
  },
  {
    id: 3,
    title: "Mass Assignment",
    description:
      "You can assign repetitive tasks and standing orders to employees in bulk.\n" +
      "\n" +
      "You can also manage the access of your employees.",
  },
  {
    id: 4,
    title: "Task Driver",
    description:
      "This is the assistant that monitors the execution of tasks. Ensures that no task is left unattended. It is an impartial outsourced operations manager who is not emotionally connected to your employees.",
  },
];

export function IndexPageBlock6(props: IIndexPageBlock6) {
  return (
    <div className="indexPageBlock6 __block-wrapper" style={{ background: "var(--color-tertiary)" }}>
      <div className="__block-container">
        <Center>
          <Heading textAlign="center" as="h1" fontWeight={900} style={{ padding: "105px 0" }}>
            Key features of the platform
          </Heading>
        </Center>
        <div className="blocks-columns">
          <div className="__column">
            {blocksData1.map((data, index) => (
              <div key={data.id} className={`d-flex flex-column mt-${Number(index > 0) * 30}`}>
                <Heading as="h2" fontSize="20px">
                  {data.title}
                </Heading>
                <Text className="mt-2 mb-5" fontSize="15px">
                  {data.description}
                </Text>
                <Image src={`/images/index_page/block_6/1_${data.id}.jpg`} />
              </div>
            ))}
          </div>
          <div style={{ width: "96px", height: "120px" }} />
          <div className="__column justify-space-evenly">
            {blocksData2.map((data, index) => (
              <div key={data.id} className={`d-flex flex-column mt-${Number(index > 0) * 30}`}>
                <Heading as="h2" fontSize="20px">
                  {data.title}
                </Heading>
                <Text className="mt-2 mb-5" fontSize="15px">
                  {data.description}
                </Text>
                <Image src={`/images/index_page/block_6/2_${data.id}.jpg`} />
              </div>
            ))}
          </div>
        </div>
        <Center>
          <Button colorScheme="red" height={65} fontSize="20px" margin="65px 0" onClick={props.onScrollToClick}>
            Choose
          </Button>
        </Center>
      </div>
    </div>
  );
}
