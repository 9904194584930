import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  Heading,
} from "@chakra-ui/react";
import "./NewPageBlock11.scss";

const blocksData = [
  {
    id: 1,
    title: "Do I need to have a technical or programming background to understand the platform?",
    description:
      "Anyone can use the platform - no programmers or complicated schemes are needed. In addition, we provide full training on how to work with the platform.",
  },
  {
    id: 2,
    title: "What if I don't want to understand the system and want everything turnkey?",
    description:
      "We have different rates and terms of cooperation. You can work individually with a consultant who will help you set up the right processes and migrate your company operations to the Tonnus platform.",
  },
  {
    id: 3,
    title: "I have a small company, will the platform suit me?",
    description:
      "The platform is suitable for companies with more than 5 employees. Smaller companies need it even more, as they often have all the functions tied up in the owner, causing the business to hit a glass ceiling very quickly. The platform will relieve the owner from operational tasks and free up time for strategic development.",
  },
];

export function NewPageBlock11() {
  return (
    <div className="indexPageBlock11 __block-wrapper">
      <div className="__block-container">
        <Center>
          <Heading as="h1" fontWeight={900} style={{ padding: "105px 0" }}>
            FAQ
          </Heading>
        </Center>

        {blocksData.map((data) => (
          <Accordion key={data.id} className="accordion" allowToggle>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left" marginRight="1em">
                  {data.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Divider style={{ marginBottom: "18px" }} />
                {data.description}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
