import React, { useState } from "react";
import { Center, Divider, Heading, IconButton, Image, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import "./IndexPageBlock10.scss";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const blocksData = [
  {
    id: 1,
    before: (
      <div className="px-6 py-4 d-flex flex-column justify-center" style={{ textAlign: "left" }}>
        <Text fontSize="24px" fontWeight="bold" children="Alexey Maksimenko" />
        <Divider className="my-3" />
        <Text fontSize="18px" fontWeight="bold" children="Before:" className="mb-1" />
        <UnorderedList spacing="0.3em" fontSize="15px">
          <ListItem>income of up to $24,000 per month;</ListItem>
          <ListItem>
            There was no clear organizational structure: as there was no understanding of what the manager was supposed
            to do, he did not do anything;
          </ListItem>
          <ListItem>
            There was no understanding of what employees were doing or if they were doing anything at all. There was no
            way to monitor important tasks;
          </ListItem>
          <ListItem>long-term tasks were simply forgotten about;</ListItem>
          <ListItem>
            employees did not have described tasks and regulations; they had to train everyone themselves.
          </ListItem>
        </UnorderedList>

        <Text fontSize="18px" fontWeight="bold" children="Owner's condition:" className="mb-1 mt-3" />
        <Text fontSize="15px">
          He was shocked because the manager wasn't coping well, had to do as much as possible with operations and
          worked 40 hours a week for the manager.
        </Text>

        <Text fontSize="18px" fontWeight="bold" children="Work process:" className="mb-1 mt-3" />
        <UnorderedList spacing="0.3em" fontSize="15px">
          <ListItem>Developed the organizational structure of the two companies;</ListItem>
          <ListItem>described the tasks and the whole process of setting them;</ListItem>
          <ListItem>implemented all the tasks in the system;</ListItem>
          <ListItem>wrote procedures for the processes where failure occurred most often.</ListItem>
        </UnorderedList>
      </div>
    ),
    after: (
      <div
        className="px-6 py-4 full-width d-flex flex-column justify-center __review-after"
        style={{ textAlign: "left" }}
      >
        <Text fontSize="18px" fontWeight="bold" children="After:" className="mb-1 mt-3" />
        <UnorderedList spacing="0.3em" fontSize="15px">
          <ListItem>
            The income after 2 months has reached <b>$40,000 a month</b>.
          </ListItem>
          <ListItem>
            <b>The owner does not train employees</b>, just adds newcomers to the system, they train themselves and then
            work fully.
          </ListItem>
          <ListItem>
            <b>Status of the resource</b>: free, it was possible to monitor the company remotely.
          </ListItem>
          <ListItem>
            Doesn't worry if all is well with the project, just{" "}
            <b>puts the supervisors in charge and everything works</b>.
          </ListItem>
          <ListItem>
            <b>Has become a mentor for owners</b> of companies from their niches and helps them grow.
          </ListItem>
        </UnorderedList>
      </div>
    ),
  },
  {
    id: 2,
    before: (
      <div className="px-6 py-4 d-flex flex-column justify-center" style={{ textAlign: "left" }}>
        <Text fontSize="24px" fontWeight="bold" children="Vitaly Teyn" />
        <Divider className="my-3" />
        <Text fontSize="18px" fontWeight="bold" children="Before:" className="mb-1" />
        <UnorderedList spacing="0.3em" fontSize="15px">
          <ListItem>the organizational structure did not reflect the work of the company;</ListItem>
          <ListItem>many functions that did not relate to the real work of the company at all;</ListItem>
          <ListItem>employees were not fulfilling the plan;</ListItem>
          <ListItem>there were cross orders due to communication problems between departments;</ListItem>
          <ListItem>employees in offices were arguing about who should perform the task.</ListItem>
        </UnorderedList>

        <Text fontSize="18px" fontWeight="bold" children="Owner's condition:" className="mb-1 mt-3" />
        <Text fontSize="15px">
          The routine took so much attention and energy that sometimes you wanted to run away from work or not come to
          your company at all.
        </Text>

        <Text fontSize="18px" fontWeight="bold" children="Work process:" className="mb-1 mt-3" />
        <UnorderedList spacing="0.3em" fontSize="15px">
          <ListItem>created an organizational structure;</ListItem>
          <ListItem>described the tasks of each employee;</ListItem>
          <ListItem>described the rules and tasks of interaction between departments;</ListItem>
          <ListItem>connected all employees to the system.</ListItem>
        </UnorderedList>
      </div>
    ),
    after: (
      <div
        className="px-6 py-4 full-width d-flex flex-column justify-center __review-after"
        style={{ textAlign: "left" }}
      >
        <Text fontSize="18px" fontWeight="bold" children="After:" className="mb-1 mt-3" />
        <UnorderedList spacing="0.3em" fontSize="15px">
          <ListItem>
            <b>Income has</b> tripled in 6 months.
          </ListItem>
          <ListItem>
            The owner <b>spends 3 days a week on the company</b>.
          </ListItem>
          <ListItem>
            <b>Employees do not need to figure out their concerns and tasks;</b> instead, they use the system`s support
            chat room to address their inquiries.
          </ListItem>
          <ListItem>
            The owner <b>launches a training course</b> for the position of "marketplace manager" and is helping niche
            colleagues with specialist training..
          </ListItem>
          <ListItem>
            The owner <b>has opened a new business</b> in another country.
          </ListItem>
        </UnorderedList>
      </div>
    ),
  },
  {
    id: 3,
    before: (
      <div className="px-6 py-4 d-flex flex-column justify-center" style={{ textAlign: "left" }}>
        <Text fontSize="24px" fontWeight="bold" children="Victor Subbotin" />
        <Divider className="my-3" />
        <Text fontSize="18px" fontWeight="bold" children="Before:" className="mb-1" />
        <UnorderedList spacing="0.3em" fontSize="15px">
          <ListItem>income $4,000;</ListItem>
          <ListItem>it took 14 hours a day to supervise employees, worked 3 years without a day off;</ListItem>
          <ListItem>after the 3rd coffee shop, there was a glass ceiling in income;</ListItem>
          <ListItem>had to repeat all tasks 100 times;</ListItem>
          <ListItem>trained employees by himself for 2 months, after that they left for competitors.</ListItem>
        </UnorderedList>

        <Text fontSize="18px" fontWeight="bold" children="Owner's condition:" className="mb-1 mt-3" />
        <Text fontSize="15px">Depressed? Downtrodden and tired. Unsure what to do next with the business.</Text>

        <Text fontSize="18px" fontWeight="bold" children="Work process:" className="mb-1 mt-3" />
        <UnorderedList spacing="0.3em" fontSize="15px">
          <ListItem>described the tasks and the whole process of setting them;</ListItem>
          <ListItem>put all the tasks into the system;</ListItem>
          <ListItem>wrote process regulations;</ListItem>
          <ListItem>put people in charge of training.</ListItem>
        </UnorderedList>
      </div>
    ),
    after: (
      <div
        className="px-6 py-4 full-width d-flex flex-column justify-center __review-after"
        style={{ textAlign: "left" }}
      >
        <Text fontSize="18px" fontWeight="bold" children="After:" className="mb-1 mt-3" />
        <UnorderedList spacing="0.3em" fontSize="15px">
          <ListItem>
            <b>Income reached $28,000</b>.
          </ListItem>
          <ListItem>
            <b>Started traveling</b>, got two days off a week.
          </ListItem>
          <ListItem>
            Opened <b>seven coffee shops</b>..
          </ListItem>
          <ListItem>
            Spends <b>5 minutes a day supervising employees</b>..
          </ListItem>
          <ListItem>
            <b>Employees are trained in 2 weeks</b> on their own.
          </ListItem>
        </UnorderedList>
      </div>
    ),
  },
];

export function IndexPageBlock10() {
  const [selectedCarouselItem, setSelectedCarouselItem] = useState(0);
  const carouselItemsLength = blocksData.length;

  const handlePrevClick = () => {
    if (selectedCarouselItem == 0) {
      setSelectedCarouselItem(carouselItemsLength - 1);
    } else {
      setSelectedCarouselItem(selectedCarouselItem - 1);
    }
  };

  const handleNextClick = () => {
    if (selectedCarouselItem == carouselItemsLength - 1) {
      setSelectedCarouselItem(0);
    } else {
      setSelectedCarouselItem(selectedCarouselItem + 1);
    }
  };

  return (
    <div className="indexPageBlock10 __block-wrapper" style={{ background: "black", paddingBottom: "105px" }}>
      <div className="__block-container">
        <Center>
          <Heading as="h1" fontWeight={900} color="white" style={{ padding: "105px 0" }}>
            Cases:
          </Heading>
        </Center>
        <div className="d-flex align-center">
          <div className="__btn-default">
            <IconButton onClick={handlePrevClick} size="lg" borderRadius="50%" aria-label="left">
              <FiChevronLeft fontSize="24px" />
            </IconButton>
          </div>
          <div className="flex-grow-1 __reviews-wrapper">
            <Image className="__image" src="/images/index_page/block_10/1.svg" />
            <Carousel
              selectedItem={selectedCarouselItem}
              swipeable={false}
              showArrows={false}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
            >
              {blocksData.map((data) => (
                <div className="d-flex __reviews-container" style={{ height: "100%" }} key={data.id}>
                  {data.before}
                  <Divider orientation="vertical" height="100%" className="px-3 __divider-v" />
                  <Divider className="py-3 __divider-h" />
                  {data.after}
                </div>
              ))}
            </Carousel>
          </div>
          <div className="__btn-default">
            <IconButton onClick={handleNextClick} size="lg" borderRadius="50%" aria-label="right">
              <FiChevronRight fontSize="24px" />
            </IconButton>
          </div>
        </div>
        <div className="d-flex justify-space-between __btns-bottom mt-4">
          <div>
            <IconButton onClick={handlePrevClick} size="lg" borderRadius="50%" aria-label="left">
              <FiChevronLeft fontSize="24px" />
            </IconButton>
          </div>
          <div>
            <IconButton onClick={handleNextClick} size="lg" borderRadius="50%" aria-label="right">
              <FiChevronRight fontSize="24px" />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}
