import React from "react";
import "./DocPage.scss";

export const PrivacyPolicyPage = () => {
  return (
    <div className="document__wrapper">
      <h1>PRIVACY POLICY</h1>
      <h3>These Policy were last updated on October 01, 2023</h3>
      <p>
        Visotsky Consulting Inc. (collectively, "we", "us" or "we") takes confidentiality and information security very
        seriously. Protecting the Personal Data of Users (hereinafter referred to as "you", "you" or "you") is one of
        our key priorities.
      </p>
      <p>
        In an effort to respect your rights and freedoms in the protection of your Personal Data, this Policy has been
        created. It explains the basis on which Personal Data is processed, how and for what purposes, and about your
        choices relating to that Personal Data.
      </p>
      <p>
        This Policy applies to all means by which we collect information. For example, we collect information in
        face-to-face conversations, telephone conversations.
      </p>
      <p>
        Please read this Policy carefully to understand how we will handle your Personal Information. If you do not
        agree with our Policy, you may not use our Website. By accessing or using the Website, you agree to the terms of
        this Policy.
      </p>
      <p>
        This Policy may change from time to time. Your continued use of this Website after we make changes to the Policy
        is deemed acceptance of those changes, so please check the Policy periodically for updates.
      </p>

      <h2>Introduction</h2>
      <p>
        This Policy is developed in accordance with the applicable laws of the countries of the European Union, the
        United Mexican States, and the United States of America.
      </p>

      <h2>What terms we use in this Policy:</h2>
      <p>
        Applications (Questionnaires) shall mean the forms for collecting your Personal Data posted on the Website and
        intended to be filled out and sent electronically through the Website. Applications are presented on the Website
        in the form of questionnaires containing prompt questions;
      </p>
      <p>
        Button shall mean a graphical element of the Website, highlighted in color and containing an inscription of a
        contrasting color, indicating a program-related action that will be performed when you click on it;
      </p>
      <p>
        Cookies are text files placed on your computer (other device) when you visit the Website through a web browser.
        You can read more about cookies{" "}
        <a target="_blank" href="/doc/cookies-policy">
          here
        </a>
        ;
      </p>
      <p>
        Processing of Personal Data shall mean any of our actions (operations) or a set of actions (operations)
        performed with or without the use of automation means with Personal Data, including collection, recording,
        systematization, accumulation, storage, clarification (update, change), extraction, use, transfer (distribution,
        provision, access), depersonalization, blocking, deletion, destruction of Personal Data;
      </p>
      <p>
        Personal Data means any information relating to you directly or indirectly. Your Personal Data also means the
        information you provide to us when you access the Website, Platform, Account, register and/or authorize on the
        Website, Platform and/or Account and use the Website, Platform, Account, Service Packages, fill out an
        Application. More detailed information about the composition of Personal Data can be found in the section on
        what Personal Data we collect;
      </p>
      <p>Information System User means an employee of ours to whom we have granted access to your Personal Data;</p>
      <p>
        Website shall mean the aggregate of text, graphics, design, images, code, software, photos, videos and other
        results of our intellectual activity contained on the Internet and located at this Website;
      </p>
      <p>
        Services shall mean the services provided by us to an unlimited number of people on the terms and conditions set
        out on the Website;
      </p>
      <p>
        Account shall mean a page that is available to you after you have authorized on the Website, that is, after you
        have confirmed your identity and left your Personal Data;
      </p>
      <p>Platform shall mean an information resource for us to provide Services/Packages of Services to Customers.</p>
      <p>Customer shall mean you, if you intend to purchase or have purchased our Services or a Service Package.</p>
      <p>
        Service Packages means the aggregate of several Services that you intend to purchase or have purchased from us.
      </p>
      <p>
        The Internet is a global system of interconnected computer networks that uses a set of Internet protocols
        (TCP/IP) to communicate between networks and devices.
      </p>

      <h2>What principles of personal data processing we adhere to</h2>
      <p>We are guided by the following principles when processing your Personal Data:</p>
      <p>
        - Obtaining Consent to the Processing of Personal Data. We process your Personal Data only with your consent,
        which can be withdrawn by you at any time. Consent is deemed to be obtained at the time you complete your
        registration on our Website after completing the Application form. Registration shall be deemed completed when
        you click on the "Register" button or similar button located under the last prompt question in the Application
        form.
      </p>
      <p>
        - Targeted Data Processing. We limit the processing of your Personal Data to achieve specific, predetermined and
        legitimate purposes. We do not use your data for other purposes incompatible with those for which it was
        collected.
      </p>
      <p>- We only process Personal Data that is necessary to achieve the stated purposes of processing.</p>
      <p>
        - Accuracy and Relevance. We take care to ensure that your Personal Data is accurate and up-to-date for the
        purposes for which it is processed. If data is incomplete or inaccurate, we take the necessary steps to clarify
        or delete it.
      </p>
      <p>
        - Destruction. Your Personal Data will be destroyed when the purposes of its processing have been achieved or
        when there is no need for its further use.
      </p>
      <p>
        We strive to ensure the protection and confidentiality of your Personal Data in accordance with these principles
        and applicable Personal Data protection legislation. If you have any questions regarding the processing of your
        data, you can always contact us for further information.
      </p>
      <p>
        We do not verify the accuracy of the information about your Personal Data that you provide when completing
        Applications on our Website. We also do not verify that this Personal Data belongs to you directly, you provide
        it as your own. We assume that you provide us with your Personal Data in your own interest and have no intention
        to violate the rights and legitimate interests of others, our rights or cause them/us losses.
      </p>
      <p>
        To collect, record, organize, organize, accumulate, store, clarify (update, modify) and retrieve your Personal
        Data, we use databases that are located in the United States. This may be necessary to ensure efficient
        Processing of your data and fulfill the stated purposes of the Processing.
      </p>
      <p>
        We endeavor to ensure the security and confidentiality of your Personal Data and to comply with all relevant
        regulations and laws related to the protection of Personal Data. If you have any questions or require further
        information regarding the processing of your data, do not hesitate to contact us. We are here to help and
        provide you with the support you need.
      </p>

      <h2>What information we receive from you</h2>
      <p>
        We collect various data about you and other users in accordance with the Policy and our objectives. This data
        may include personal information about users, as well as technical and other information related to their
        activities on our Website.
      </p>
      <p>
        Technical information, such as cookies, helps us identify you and other users. Cookies are text files that we
        use to Process data about how users interact with our Website, Platform and Account. For example, we may know
        which pages have been visited, how much time you or another user has spent on a page, or what services have been
        ordered. If you wish, you can disable the use of cookies in your browser settings. The{" "}
        <a target="_blank" href="/doc/cookies-policy">
          Cookies Policy
        </a>{" "}
        contains more detailed information about their processing.
      </p>
      <p>
        Technical information also includes information that is automatically transmitted to us when you use our
        Website, Platform and/or Account through the software on your device. Such information includes:
      </p>
      <p>- Internet activity data, such as pages visited, date and time of url visits, etc.</p>
      <p>
        - Information about the device and browser you use to access the Internet, including ip address and device type.
      </p>
      <p>
        - Data about interactions with advertisements we display outside our Website, such as number of views, frequency
        and depth of views.
      </p>
      <p>
        We use third-party user location software (maxmind.com), web analytics (Google Analytics) and end-to-end
        business analytics (Roistat) to obtain additional information about user interactions with our services.
      </p>
      <p>
        The collection of anonymized data using third-party software is determined by the copyright holders of such
        software. We do not control this process and have no direct influence on the composition and conditions of such
        anonymized data collection.
      </p>
      <p>
        We process Personal Data, technical information and other information of Customers (Clients) for the duration of
        the agreement between them and us. For other users who are not Customers (Clients), we process their Personal
        Data for the duration of their use of our Website and our other resources.
      </p>
      <p>
        When using our Website, Platform and/or Account, third party software may be used. As a result of such use, your
        data may be provided and transferred to third parties in an anonymized form. This means that the transmitted
        data cannot be used to identify specific individuals and will remain anonymous.
      </p>

      <h2>What we do with your Personal Data</h2>
      <p>
        Processing of your Personal Data includes such activities as recording, systematization, accumulation, storage,
        clarification (updating, modification), retrieval, use, transfer (dissemination, provision, access),
        depersonalization, blocking, deletion and destruction of your Personal Data. This applies to data for the
        processing of which we need to obtain your written consent.
      </p>
      <p>
        By registering, authorizing and using our Website, Platform and Account, as well as filling out Applications
        (Questionnaires), you consent to the processing of your Personal Data. This data may be transferred to third
        parties as part of our agreement with you (if you are a Customer or Client) or for the implementation of
        partnership programs with such third parties, even if such transfer of data takes place outside the United
        States, Mexico or the European Union (the place of storage of personal data), to the territory of other
        countries (cross-border transfer).
      </p>
      <p>We process your Personal Data with or without the use of automation tools.</p>
      <p>Please note that your Personal Data is processed by us in the United States, to which you consent.</p>
      <p>Our information systems Process Personal Data of the following categories of data subjects:</p>
      <p>- our employees;</p>
      <p>- our Customers (Clients);</p>
      <p>
        - performers, contractors and other third parties who provide services and/or carry out work commissioned by us;
      </p>
      <p>- participants of the events we organize;</p>
      <p>- subscribers to our newsletter;</p>
      <p>- media partners, invited guests and presenters at events we organize.</p>
      <p>
        This Policy applies to all processes related to Personal Data Processing, including collection, recording,
        systematization, accumulation, storage, clarification, extraction, use, transfer, depersonalization, blocking,
        deletion and destruction of data. These processes may be carried out with or without the use of automation
        tools.
      </p>
      <p>
        By using our Website and the GetCourse Platform and other resources provided by us, you unconditionally consent
        to our Personal Data Processing Policy and Terms. You may also agree to this Policy by subscribing to our
        mailing lists or by providing us with written consent to the Processing of Personal Data.
      </p>
      <p>
        Our Website is not directed to people who are under the age of eighteen (18) in the European Union (depending on
        jurisdiction) and Mexico, or sixteen (16) in the United States under local law. We do not collect information
        from such people. If you are under these ages, you should not provide us with any personal information on or
        through the Website.
      </p>
      <p>
        We do not knowingly collect information from children under the age of thirteen (13). If we inadvertently
        receive personal information from a child under the age of thirteen (13) without parental consent, we will
        delete that information immediately.
      </p>
      <p>
        If you have reason to believe that we have Personal Information from a child under the age of thirteen (13),
        please contact us at <a href="mailto:support@visotsky.us" children="support@visotsky.us" />.
      </p>
      <p>We use the Personal Data and information you provide to us solely for the following purposes:</p>
      <p>
        - To fulfill our obligations to you if you are our Customer (Client) and use our Website, Platform, Account, and
        materials and other intellectual resources in obtaining our Services.
      </p>
      <p>- Establishing and maintaining communications with you.</p>
      <p>- Sending you communications of an informational and other nature to your email address.</p>
      <p>- Improving the quality of service and upgrading our Website, Platform and Account.</p>
      <p>- Registering you on the Website, Platform and Account and helping you manage your Account.</p>
      <p>- Administering justice when requested by authorized authorities.</p>
      <p>- Implementation of partnership programs with third parties.</p>
      <p>- Complying with legal requirements.</p>
      <p>- Notifying you about the services we provide and how we provide them.</p>
      <p>- Analyzing the results you have achieved by using our Services.</p>
      <p>
        - Contacting you to clarify your intention to purchase our Services, information about which is provided on the
        Website.
      </p>
      <p>
        - Contracting with you to provide our Services, including invoicing, notices and information related to the
        provision of the Services.
      </p>
      <p>- Processing your inquiries and Applications submitted to us.</p>
      <p>
        - Developing and adjusting our marketing policy to suit your preferences based on your location and job role.
      </p>
      <p>
        We aim to use your Personal Data only for the purposes set out in this section and within the law to ensure that
        your privacy and data security are protected.
      </p>
      <p>We do not verify the accuracy of the Personal Data you provide or your capacity to act.</p>

      <h2>Here is a list of your Personal Data that we process:</h2>
      <p>- Last name, first name, middle name;</p>
      <p>- E-mail address;</p>
      <p>- Contact phone number;</p>
      <p>- Messaging accounts;</p>
      <p>- Links to social media profiles (pages);</p>
      <p>- Your image (photograph);</p>
      <p>
        - Additional information that you provide to us through the Website, Platform, Application, Account and in the
        course of providing Services within the framework of Service Packages.
      </p>
      <p>
        This information enables us to contact you, to provide you with our services and to improve our service and meet
        your needs. We process this information in compliance with applicable Personal Data legislation and with your
        consent, including where required. We always strive to keep your Personal Data secure and confidential and only
        use it for the purposes set out in this Policy.
      </p>

      <h2>Our rights in relation to the Personal Data you have provided to us</h2>
      <p>
        We may conduct statistical and research studies based on your anonymized Personal Data. We may also provide
        access to these studies to third parties for advertising targeting. When you submit an Application on the
        Website, register on the Platform or create a Personal Account, you consent to the transfer, processing,
        storage, and other operations with your Personal Data, including such research and receipt of targeted
        advertising. However, you may withdraw this consent at any time by contacting us at the address listed on the
        Website.
      </p>
      <p>
        We may disclose information about you to law enforcement or other government agencies as part of a legal process
        or investigation pursuant to a court order, an enforceable request, or as part of a cooperative effort under
        applicable law.
      </p>
      <p>
        We may share information about you with third parties to detect and combat fraudulent activity, technical
        problems or security issues.
      </p>
      <p>
        We may provide access to your Personal Data to other Website and Platform users, webinar participants, online
        and offline events, if such transfer is necessary to fulfill our obligations to other users or to you under
        agreements entered into, including in anonymized form.
      </p>
      <p>
        We may set requirements for mandatory information (Personal Data) that you need to provide in order to use the
        Website, Platform and/or Account. However, if certain information is not labeled as mandatory, its provision or
        disclosure is at your discretion.
      </p>
      <p>
        We retain your Personal Data only for the time necessary to fulfill the purposes of the Personal Data
        Processing. The retention period for Personal Data may be prescribed by law or provided for by your consent
        provided to us. After the expiry of the retention periods, your Personal Data will be destroyed or anonymized.
      </p>
      <p>
        In accordance with the purposes of processing and legal requirements, we may transfer Personal Data to the
        following categories of third parties:
      </p>
      <p>- our subsidiaries and companies within our group of companies;</p>
      <p>- to our external contractors and companies with whom we have contracts;</p>
      <p>- governmental and other authorities as required by law.</p>
      <p>
        The transfer of Personal Data to the territory of foreign countries that do not provide an adequate level of
        protection of your rights (countries that are not signatories to the Convention for the Protection of
        Individuals with regard to Automatic Processing of Personal Data ETS No. 108) may only take place with your
        consent and/or for the performance of a contract and/or in accordance with legal requirements.
      </p>
      <p>
        For transfers of Personal Data received from the European Union to third parties in countries that do not
        provide an adequate level of protection for Personal Data under the GDPR, contracts containing "Standard
        Contractual Clauses" (SCC) adopted by the European Commission or a competent authority and approved by the
        European Commission are concluded with the relevant third parties to ensure that your rights and freedoms are
        respected.
      </p>
      <p>
        To obtain information about the list of third parties to whom your Personal Data is transferred and copies of
        the standard contractual clauses used in such contracts, you should contact us. We are obliged to provide you
        with this information upon your request.
      </p>

      <h2>What rights you have in relation to the Personal Data you share with us</h2>
      <p>Please familiarize yourself with the following rights:</p>
      <p>
        - When you post information about yourself on our Website, Platform, Application (Questionnaire), or My Account,
        you may make it publicly available. Please understand that such information may be detected by search engines
        and may involve risks of disclosure of personal, family and other confidential information.
      </p>
      <p>
        - You have the right to delete or modify the information you provide at any time. You can do so by taking the
        necessary actions on our Website, Platform and in My Account, or, if you cannot do so, by contacting us at
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" />. Please note, however, that in some cases
        we may continue to use your Personal Data where permitted by law.
      </p>
      <p>
        - You have the right to request confirmation that we are processing your personal information. If processing
        does take place, you have the right to review your Personal Data and to obtain information about the purposes of
        the processing, the categories of data processed, the actions taken with the data, the recipients and safeguards
        for the transfer of the data to third parties, the processing timeframe, the sources of the data and the
        application of automated processing to your data. You also have the right to obtain a list of the Personal Data
        we process.
      </p>
      <p>
        - If we use your consent as a basis for processing your Personal Data, you have the right to withdraw your
        consent at any time. Each consent to processing that you provide to us sets out the procedure for withdrawing
        consent. If you wish to withdraw any of the consents we have provided, you may use the provided procedure set
        out in such consent.
      </p>
      <p>
        - You have the right to request that your Personal Data be corrected if you find errors or inaccuracies in it.
        For this purpose, you may make changes to your Personal Information, taking into account the purposes for which
        it is processed.
      </p>
      <p>
        You also have the right to initiate the restriction of processing of all or part of your Personal Data in the
        following cases:
      </p>
      <p>
        - If you believe that the data contains inaccuracies, we will temporarily restrict the processing to confirm its
        correctness.
      </p>
      <p>
        - If you discover that your data is being improperly processed and would prefer to restrict its use instead of
        deleting it.
      </p>
      <p>
        - If we no longer need your Personal Data but you may need it to protect your rights in legal claims, lawsuits
        or proceedings.
      </p>
      <p>
        If you object to the processing of your data, we will temporarily restrict the processing to determine whether
        our legitimate grounds for this processing override your grounds of objection.
      </p>
      <p>
        You may request the deletion of your Personal Data from our systems and/or other material sources if one of the
        following conditions is met:
      </p>
      <p>- Your Personal Data is no longer needed for the purposes for which it was collected.</p>
      <p>
        - You withdraw your consent to the processing of your data and there is no other lawful basis for the
        processing.
      </p>
      <p>
        - You object to the processing of your data and we have no lawful basis for further processing of your data.
      </p>
      <p>- The processing of the data is unlawful.</p>
      <p>Data deletion is required to comply with our legal obligation under statutory requirements.</p>
      <p>
        If you were a minor under the age of 16 (or other age under the applicable laws of your country) at the time
        your Personal Data was collected and the data was not collected with the consent of your legal representative
        for the purpose of providing services over the Internet. Further details can be found in the section on what we
        do with your Personal Data.
      </p>
      <p>
        - You have the right to request a list of your Personal Data provided by us for processing in a structured,
        universal and machine-readable format. You may also instruct us to transfer this list to a third party if we
        have the appropriate technical capability to do so. It is important to note that if we transfer Personal Data to
        a third party in this way, we are not responsible for any actions that the third party may take with your
        Personal Data in the future.
      </p>
      <p>
        -You have the right to object to the processing of some or all of your Personal Data if the processing is
        carried out for the purposes specified when you provided it to us. However, there are cases where our legitimate
        grounds for processing may override your interests, rights and freedoms, or the processing is necessary to
        justify or contest legal claims.
      </p>
      <p>
        You have the right to request that we restrict the processing of your Personal Data for the purposes of
        marketing activities on our part.
      </p>
      <p>
        In the event that we violate your rights in any way with regard to the Processing of Personal Data, you have the
        right to lodge a complaint with the supervisory authority responsible for data control and protection.
      </p>
      <p>
        We are committed to respecting your rights and ensuring that your Personal Data is processed securely and
        responsibly. If you have any questions or queries about this, please contact us at{" "}
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" />. We are always available to assist you.
      </p>

      <h2>When we start processing your Personal Data. Period of your consent to the processing of Personal Data</h2>
      <p>
        We begin processing your Personal Data as soon as we receive your consent to do so. This processing continues
        until your consent expires or you withdraw it.
      </p>
      <p>
        The validity of your consent to the Processing of Personal Data is indefinite and begins when we obtain your
        consent when you complete an Application on the Website or otherwise explicitly.
      </p>
      <p>
        If you decide to withdraw your consent to the Processing of Personal Data, you may do so at any time. To do so,
        you must send a personal statement to us at{" "}
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" />. As soon as we receive your application,
        the processing of your Personal Data will cease immediately and all your Personal Data will be destroyed.
      </p>

      <h2>What's with the privacy?</h2>
      <p>
        We will keep your Personal Data confidential and will not disclose it to third parties without your consent,
        except to the extent such disclosure is required by applicable law.
      </p>

      <h2>Protecting your Personal Data</h2>
      <p>
        We take necessary and sufficient legal, organizational and technical measures to ensure the protection of the
        Personal Data you provide to us. This allows us to protect your data from unauthorized or accidental access,
        destruction, modification, blocking, copying, distribution and other unauthorized actions of third parties.
      </p>
      <p>
        To do this, we restrict access to your information to other users of the Website and Platform, our employees and
        partners, except where providing such information is necessary to fulfill our obligations to you or as required
        by law.
      </p>
      <p>
        We also ensure that your submitted information, including Personal Data, is not combined with third party
        statistics. We use your information only for internal statistics and do not disclose it to third parties except
        as set out in our Policy.
      </p>
      <p>
        It is important to note that we do not sell or transfer your information to individual third parties. Such
        information may only be transferred when we partially or completely reorganize our company.
      </p>
      <p>
        We take technical and organizational measures to enable you to access and edit your submitted Personal
        Information as necessary.
      </p>
      <p>
        If we discover unlawful processing of your Personal Data, whether by us or by a person acting on our behalf, we
        are obliged to immediately stop such unlawful processing. If the lawfulness of the processing becomes
        impossible, we are obliged to destroy your Personal Data. We take all of these measures in accordance with the
        law and as part of our obligations to you.
      </p>
      <p>We take the following steps to protect your Personal Data:</p>
      <p>- We ensure the safety of the carriers of Personal Data.</p>
      <p>- We identify and authenticate all users of our information system (Website) and access objects.</p>
      <p>
        - We determine the list of persons who are granted access to your Personal Data as part of their job duties.
      </p>
      <p>- Appoint a person responsible for ensuring the security of Personal Data in the information system.</p>
      <p>
        - Manage the user accounts of our information system (Website) and apply measures to differentiate user access
        rights.
      </p>
      <p>- We apply anti-virus protection of the information system with Personal Data.</p>
      <p>- We conduct periodic inspections of the conditions of Personal Data Processing.</p>
      <p>
        - We organize training of users of our information system (Website) through briefings, especially those who have
        permanent or temporary access to your Personal Data.
      </p>
      <p>Please note the following points:</p>
      <p>
        - The Website, Platform, and Account are not publicly available sources of Personal Data. However, through
        certain actions by you or others, your Personal Data may become available to an unspecified number of people,
        and you consent to this.
      </p>
      <p>
        - We may send newsletters and promotional materials to the email address and contact phone number you provide
        when you register on the Website, Platform and/or in your Account or complete an Application. You consent to
        such sending, however, you also have the right to opt-out of informational or marketing mailings.
      </p>
      <p>
        - We are not responsible for the use of your Personal Data by third parties with whom you interact when using
        the Website, Platform and/or Account.
      </p>
      <p>
        If you have reason to believe that your interaction with us is no longer secure, for example, if you suspect
        that the security of your account has been compromised or your credentials are no longer secure, please notify
        us immediately.
      </p>
      <p>
        To do so, you can send an email to <a href="mailto:support@visotsky.us" children="support@visotsky.us" />. We
        take such communications very seriously and respond immediately to any suspicions or concerns about the security
        of your Personal Data and Accounts.
      </p>
      <p>
        Our staff will take the necessary steps to review the situation and ensure the security of your data. Your
        safety and the protection of your Personal Data is our priority, and we do everything we can to keep it safe and
        secure.
      </p>

      <h2>Notification of Cookie Usage</h2>
      <p>
        Cookies are used on our Website. Please be aware that Cookies are not Personal Data as they are non-personal
        information.
      </p>
      <p>
        We collect and store the IP address, domain name, browser type and operating system used on your device when you
        visit our Website. This information is collected in a depersonalized form and is used to maintain traffic
        statistics. We analyze this information to promptly identify and correct technical problems with our Website.
      </p>
      <p>
        Cookies allow us to improve your experience with the Website by providing you with more convenient features and
        personalized content. However, please remember that you can change your browser settings at any time to opt out
        of cookies or to receive notifications when they are sent.
      </p>
      <p>
        We value your privacy and are committed to keeping your data as secure as possible. For more information on how
        we use Cookies, please see our{" "}
        <a target="_blank" href="/doc/cookies-policy">
          Cookies Policy
        </a>
        . If you have any questions or require further information, please contact us at{" "}
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" />.
      </p>

      <h2>Important information for residents of the State of California (USA)</h2>
      <p>
        In compliance with the California Consumer Privacy Act (CCPA), we provide the following information about the
        categories of Personal Data we collect, use, and disclose about California residents and their rights.
      </p>
      <p>
        During the past 12 months, we collected the following categories of Personal Data in accordance with the CCPA:
      </p>
      <p>Category A: Identifiers such as name, contact information, IP address, and other online identifiers.</p>
      <p>
        Category B: Personal Information as defined in the California Customer Records Act, such as name, contact
        information, medical information, insurance information, education information, employment information, and
        government-issued identification numbers.
      </p>
      <p>
        Category C: Characteristics of protected classifications under California or U.S. federal law, such as age, sex,
        disability status, primary language, race, citizenship, and marital status.
      </p>
      <p>Category D: Commercial information such as transaction information and purchase history.</p>
      <p>Category E: Internet or online activity information, such as browsing history and Website interactions.</p>
      <p>
        Category F: Audio, electronic, visual and similar information, such as photographs or audio and video recordings
        created in connection with our business activities.
      </p>
      <p>
        Category G: Inferences drawn from any of the Personal Data listed above to create a profile, such as an
        individual's preferences and abilities.
      </p>
      <p>
        If you are a California resident, you have certain rights with respect to your Personal Data, such as the right
        to access your data, the right to erasure, the right to block the transfer of your data to third parties, and
        others. You can read your rights in this Policy.
      </p>
      <p>
        Sometimes our actions may be considered a "sale" of Personal Data under the CCPA, Data Categories A, B, C, E, F,
        G. This term is broadly interpreted to include the transfer of Personal Data to third parties who are not
        service providers or acting at your direction. We do not sell your Personal Data; sharing information from
        publicly available public records is not considered a "sale" of Personal Data. In addition, in some cases, we
        may share your Personal Information with our subsidiaries or contractors for limited purposes.
      </p>
      <p>
        We are committed to protecting your privacy and ensuring that we have complete control over your data. If you
        have any questions or need additional information, please contact us at{" "}
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" />.
      </p>
      <p>
        In accordance with the CCPA, we have disclosed the following categories of Personal Data listed in the law to
        third parties for our operational business purposes as outlined in this Policy within the preceding 12 months:
      </p>
      <p>Category A: Identifiers such as name, contact information, IP address and other online identifiers.</p>
      <p>
        Category B: Personal information as defined in the California Customer Records Act, such as name, contact
        information, education information, medical information, insurance information, employment information, and
        state-issued identification numbers.
      </p>
      <p>
        Category C: Characteristics of protected classifications under California or federal law, such as age, sex,
        disability status, primary language, race, citizenship, and marital status.
      </p>
      <p>Category D: Commercial information, such as transaction information and purchase history.</p>
      <p>
        Category E: Internet or online activity information, such as browsing history and interactions with our Website.
      </p>
      <p>Category F: Audio, electronic, visual and similar information such as calls and videos.</p>
      <p>
        Category G: Inferences drawn from any of the Personal Information listed above to create a profile, such as an
        individual's preferences and abilities.
      </p>
      <p>
        Information about the third parties to whom these categories of Personal Data are shared can be found in the
        section on our rights in relation to your Personal Data.
      </p>
      <p>
        We strive to be transparent about the collection and use of your data and provide this information in accordance
        with the CCPA so that you can make informed decisions about your Personal Data. If you have any questions or
        need further clarification, please contact us at{" "}
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" />.
      </p>
      <p>
        If you are a California resident, you have certain rights with respect to your Personal Data under the CCPA:
      </p>
      <p>
        You may request information from us about the categories of Personal Data we have collected about you and the
        source categories from which that data was obtained.
      </p>
      <p>
        You may also request specific portions of your Personal Data that we have collected and the business or
        commercial purpose for collecting that data, if such information is applicable.
      </p>
      <p>
        You have the right to request information about the categories of Personal Data that we have shared or disclosed
        with other parties and the categories of third parties to whom that data has been shared or transferred, if any.
      </p>
      <p>
        You may request the deletion of your Personal Data (personal information) that has been collected by us. To do
        so, you must send your request to <a href="mailto:support@visotsky.us" children="support@visotsky.us" /> and we
        will verify the request, which may require you to confirm that you have control over the email address
        associated with your Personal Data and confirm that it is you who is actually interacting with us. If necessary,
        we may ask for additional information to verify your request, and if you are making a request on someone else's
        behalf, additional confirmation may be required.
      </p>
      <p>
        In addition, you have the right to opt out of having your Personal Data (personal information) "sold" to third
        parties and to limit the use of your "sensitive personal information" (as defined in the CCPA) for behavioral
        advertising purposes. The right to opt-out has certain exceptions, for example, it does not apply to sharing
        information with vendors who provide the services we need. An opt-out request will not affect our business
        operations unless there is a "sale" of your Personal Data.
      </p>
      <p>
        To exercise your rights, you may send an email to{" "}
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" /> and inform us of your intention to opt
        out of the sale or sharing of your Personal Data or to limit the use of your "sensitive personal information."
      </p>
      <p>
        We are committed to responding to requests under the CCPA within 45 days, but this period may be extended to 90
        days if operationally necessary.
      </p>
      <p>
        It is important to note that you have the right to be free from unlawful discrimination in the exercise of your
        rights under the CCPA. This means that we cannot refuse to provide services to you or change the terms of
        service because of your request to protect your Personal Information.
      </p>
      <p>
        CalOPPA (California Online Privacy Protection Act) is a law that requires us to post this Policy on our Website.
      </p>
      <p>
        We do not support the "Do Not Track" ("DNT") feature, which allows users to set a preference in their web
        browser so that their online activities are not tracked by third parties, including Internet services.
      </p>
      <p>
        It is important to note that the provisions of this section apply only to residents of the State of California
        and shall prevail in the event of a conflict with other provisions of the Policy.
      </p>

      <h2>Important information for EU and EEA residents</h2>
      <p>
        If you are a resident of the European Union (European Economic Area), you have certain data protection rights
        under the General Data Protection Regulation 2016/679 (GDPR). The GDPR applies to these Terms.
      </p>
      <p>
        To find out what Personal Data we hold about you, to request erasure or to exercise other rights under this
        section, you may email <a href="mailto:support@visotsky.us" children="support@visotsky.us" /> to make a request.
      </p>
      <p>You are granted the following rights to protect your Personal Data:</p>
      <p>
        - The right to access, update or delete your Personal Data. You may request access to, update information about,
        or erasure of your Personal Data in accordance with this section.
      </p>
      <p>
        - Right to rectification. If your Personal Data is incomplete or inaccurate, you have the right to have it
        corrected.
      </p>
      <p>- Right to object. You may object to our processing of your Personal Data.</p>
      <p>- Right to restriction. You may request that we restrict our processing of your Personal Data.</p>
      <p>
        - Right to data portability. You may receive a copy of your Personal Data that we hold about you in a
        structured, machine-readable and commonly used format.
      </p>
      <p>
        - Right to withdraw consent. You may withdraw your consent to the processing of your Personal Data at any time.
        We will no longer have the right to continue to process your Personal Data based on the consent you have
        previously provided.
      </p>
      <p>
        - The right not to be subject to automated decision-making, including profiling. This right allows you not to be
        subject to automated decision-making that could significantly affect you.
      </p>
      <p>
        - The right to lodge a complaint with the European Union supervisory authority (or your country's supervisory
        authority). If you believe that we are violating your rights in relation to the collection and use of your data,
        you have the right to lodge a complaint with your country's data protection authority.
      </p>
      <p>
        We are committed to complying with the GDPR and respecting your data protection rights by enabling you to
        exercise those rights with respect to your Personal Data.
      </p>
      <p>
        Under this Policy and this section, you agree that we have the right to request verification of your identity
        before responding to requests related to the exercise of your rights. This may be necessary to ensure the
        security and protection of your data and to prevent unauthorized access to your personal information.
      </p>
      <p>
        The provisions of this section of the Policy apply solely to you if you are a citizen of the European Union
        (European Economic Area) and shall override (and supersede) the provisions of other sections of this Policy
        where there is a conflict.
      </p>

      <h2>Important information for residents of the United Mexican States</h2>
      <p>
        We are the data controller of your Personal Data and are responsible for the processing of Personal Data that is
        subject to the Federal Law on the Protection of Personal Data held by Individuals of 05.07.2010 (Ley Federal de
        Protección de Datos Personales en Posesión de los Particulares, LFPDPPP) hereinafter referred to as the Law.
      </p>
      <p>
        We use your Personal Data in accordance with the purposes set out in this Policy. All of these purposes, with
        the exception of marketing purposes, are necessary to maintain the relationship between you and us. If you wish
        to opt out of receiving marketing communications from us, you can email us at{" "}
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" /> to have your contact information removed
        from our marketing databases.
      </p>
      <p>
        We transfer your Personal Data to third parties as described in this Policy, both within and outside of Mexico.
        For such transfers of Personal Data, we have obtained your consent or your consent is not required, except for
        transfers of Personal Data in the event of a merger, consolidation or sale of our assets or our affiliates. In
        the event of a merger, consolidation or sale of assets, we will transfer your Personal Data to. Successor
        Company. Your use of the Services/Service Packages constitutes your consent to such transfer. If you do not
        consent to such transfer of Personal Data, you may email us at{" "}
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" />.
      </p>
      <p>
        ARCO's Rights. We are required to respond to requests related to your exercise of ARCO rights within 20 business
        days of receiving your request. If necessary and in accordance with the Act, this timeframe may be extended to
        40 business days. Our response is provided electronically to your e-mail address that was provided in the
        request.
      </p>
      <p>
        When we grant your request to access, correct, or delete Personal Data, we take the appropriate action as
        requested within the timeframes specified in the Act. We also inform you of the implementation of actions
        related to the exercise of your ARCO rights.
      </p>
      <p>
        In the event that we cannot fulfill your request, we will provide you with a reasoned notice of the reasons for
        refusing to access, correct or delete Personal Data.
      </p>
      <p>
        We will not require you to provide payment for your exercise of your ARCO rights, except as required by the Act,
        when your requests are excessive or repetitive. In such cases, we may establish a reasonable fee in accordance
        with the Act.
      </p>
      <p>
        If we need additional information from you under this section to verify your identity or to understand what
        information you intend to access, correct, cancel or object to, we may request additional documents from you
        within 5 days after we receive your request. You will have 10 days to respond to our additional request,
        beginning on the day following the day we receive it. If you do not respond within this period, your request
        will be deemed not received. We will provide an email response within 20 days of receipt of your request or
        additional information, if applicable. We will fulfill your request within 15 days of receiving all required
        documents.
      </p>
      <p>
        You have the right to withdraw your consent to the Processing of Personal Data if we process your data based on
        your consent. To do so, you send your request by email to:{" "}
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" /> marked "Withdrawal of consent" and a
        brief statement of your request. If you withdraw your consent to the processing of Personal Data, your ability
        to use the Services/Service Packs, Personal Area, Website or Platform may be restricted.
      </p>
      <p>
        We will respond to your requests in accordance with the applicable laws of the United Mexican States and the
        Act. We may refuse to process your requests if they could jeopardize the privacy of others or our intellectual
        property.
      </p>
      <p>
        The provisions of this section apply only to citizens of the United Mexican States and shall override (and
        supersede) the provisions of other sections of this Policy to the extent there is a conflict.
      </p>

      <h2>Final provisions</h2>
      <p>
        We expressly reserve the right to make changes to this Policy. You should read the current text of the Policy
        each time you access our Services (Service Packages), Website, Platform and/or Account.
      </p>
      <p>
        The new version of the Policy comes into effect from the moment it is posted on the Website. If you continue to
        use our Services/Packages of Services, Website, Platform and/or Account after the new version of the Policy has
        been posted, it means that you fully accept the updated version of the Policy and its terms.
      </p>
      <p>
        If you do not agree to the terms of the Policy, please do not continue to use our Services/Service Packages,
        Website, Platform and Account.
      </p>
      <p>
        Our contact information is: Visotsky Consulting inc, Address: 368 9th Ave 6FL New York, NY 10001, Email:
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" />, Phone:{" "}
        <a href="tel:+13476040113" children="+1 347 604 0113" />.
      </p>

      <h2>Avoiding contradictions</h2>
      <p>
        Where an agreement between us and you contains provisions regarding the use of your Personal Data, the
        provisions of the Policy and that agreement apply to the extent not inconsistent with the Policy.
      </p>
    </div>
  );
};
