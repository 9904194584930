import React from "react";
import "./DocPage.scss";

export const TermsOfUsePage = () => {
  return (
    <div className="document__wrapper">
      <h1>TERMS OF USE</h1>
      <h3>These Terms were last updated on October 01, 2023</h3>
      <h3>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE WEBSITE AND PAYING FOR OUR SERVICES.</h3>

      <p>
        By using the Website, you signify your agreement to everything contained in these Terms of Use. If you do not
        agree to these Terms of Use, you may not use the Website. In addition, when you receive or intend to receive our
        services, you are also subject to our guidelines, terms, conditions and agreements applicable to those services
        posted on the Website. If these Terms of Use do not comply with the guidelines, terms, conditions and agreements
        applicable to those services, these Terms of Use shall prevail.
      </p>
      <p>
        Visotsky Consulting Inc. currently provides users with access to company building and management training
        resources (training videos, training books, software, online and offline events (provided they are included in
        the applicable tariff), etc.), various reference and communication tools (newsletters, blogs, articles, etc.),
        forums, as well as advertising and marketing services, social networking services and personalized content
        (collectively, the "Services").
      </p>
      <p>
        You understand and agree that the Services may include sponsorship or advertising. Unless expressly stated
        otherwise, any new features that supplement the current Terms of Use, including the release of new content, any
        and all Visotsky Consulting Inc. services are governed by these Terms of Use.
      </p>
      <p>
        You understand and agree that any Services are provided "AS IS" and that Visotsky Consulting Inc. is not
        responsible for the timeliness, deletion, delivery problems or failure to store any user communications or
        personalization settings resulting from technical network or software failures.
      </p>

      <h2>Privacy Policy.</h2>
      <p>
        Please read our{" "}
        <a target="_blank" href="/doc/privacy-policy">
          Privacy Policy
        </a>
        , which governs how we handle your personal information when you visit our website and make purchases.
      </p>

      <h2>Terms of purchase and return policy</h2>
      <p>
        Please review our{" "}
        <a target="_blank" href="/doc/terms-of-payment-and-refunds">
          Terms of payment and refunds
        </a>{" "}
        policy, as by purchasing from us you are agreeing to abide by these terms.
      </p>

      <h2>Restrictions on the use of our content.</h2>
      <p>
        The contents of this website ("Content"), such as logos, illustrations, text and graphics, widgets, icons,
        images, audio and video clips, digital downloads, databases and software, are the property of Visotsky
        Consulting Inc. or the property of our licensors or licensees, and the compilation of the Content on the Website
        is the exclusive property of Visotsky Consulting Inc. and is protected by United States and international
        copyright laws, treaties and conventions. All software used on the Website is also the property of Visotsky
        Consulting Inc. or the property of our software suppliers and is protected by United States and international
        copyright laws, treaties and conventions.
      </p>
      <p>
        We grant you a limited license to access and make personal use of the Website. No Content on the Website or any
        other Internet site that we own, operate or control may be copied, reproduced, republished, republished,
        downloaded (other than page caching), published, transmitted or distributed in any way or sold, resold or
        otherwise exploited for any commercial purpose. Your use of the Content on any other website or computer
        environment is strictly prohibited.
      </p>
      <p>
        The license granted to you does not include and specifically excludes any rights to: resell or commercially
        exploit the Website or any Content; collect and use any product listings, descriptions or prices; make any
        derivative use of the Website or Content; download or copy account information for the benefit of anyone else;
        or use any form of data mining, robots or similar data gathering and extraction tools. You may not create or use
        framing techniques to incorporate any marks, Content or other proprietary information, or use any meta tags or
        any other "hidden text" utilizing any such intellectual property, without our express written consent and the
        consent of each respective owner. Any unauthorized use automatically terminates the license granted to you under
        these Terms. You are granted a limited, revocable, and non-exclusive right to create a hyperlink to our home
        page only, provided that the link does not portray us, our licensors or licensees, or their respective Services
        in a false, misleading or derogatory, or otherwise offensive material. You may not use any intellectual property
        of ours or any such party as part of the link without our express written consent and each such party.
      </p>
      <p>
        <b>Changes to the Terms.</b> These Terms or any part thereof may be modified by us, including adding or deleting
        Terms of Use at any time, and such modifications, additions or deletions will be effective immediately upon
        posting. Your use of the Websites after such posting shall be deemed your acceptance of such modifications,
        additions or deletions.
      </p>
      <p>
        <b>Changes to the Website.</b> We may change or discontinue any service or feature of the Website at any time,
        including but not limited to content, availability and equipment required for access or use.
      </p>
      <p>
        <b>Account.</b> You may be given the opportunity to register via an online registration form on the
        website/platform which will allow you to receive information from us within the Websites. We will use the
        information you provide to us in accordance with the Privacy Policy. By registering, you represent and warrant
        that all information you provide is current, complete and accurate to the best of your knowledge. You agree to
        maintain and promptly update your information on the Sites so that it remains current, complete and accurate.
        You are responsible for obtaining and maintaining all connections, computer software, hardware and other
        equipment necessary to access and use the Sites and for all related costs. Purchasing certain products and
        services on Visotsky Consulting Inc. may require you to register for a user account ("User Account"). If you
        choose to register, you agree to provide true, complete and accurate registration information ("Registration
        Information"). You are responsible for updating and maintaining the accuracy of the Registration Information. If
        you provide any Registration Information that is untrue or inaccurate, outdated or incomplete, or if Visotsky
        Consulting Inc. suspects that your Registration Information is untrue, inaccurate or incomplete, Visotsky
        Consulting Inc. may, in its sole discretion, suspend, terminate or deny future access to Visotsky Consulting
        Inc. or the Website. Registration information will be governed by the Visotsky Consulting Inc. Privacy Policy
        (which is incorporated herein by reference). You are responsible for maintaining the secrecy and security of any
        personal or user account information. You are responsible for any conduct on Visotsky Consulting Inc. under your
        user account. Visotsky Consulting Inc. is not responsible for any unauthorized use of your user account. If you
        believe that your user account has been used in an unauthorized manner, you must notify Visotsky Consulting Inc.
        immediately. Only an authorized license user may use a password-protected account with Visotsky Consulting Inc.
        If anyone lends or discloses their username and password or otherwise knowingly or unknowingly provides
        unauthorized access to paid products, the original site licensee is responsible and will be billed for all
        purchases the unauthorized user makes on Visotsky Consulting Inc.
      </p>
      <p>
        <b>Web Forums.</b> Visotsky Consulting Inc. may provide its members with forum access for you and other users,
        the ability to send, post, display, transmit and/or exchange information, ideas, opinions, photographs, images,
        videos, creative works or other information, messages, transmissions or materials to Visotsky Consulting Inc.
        and other users through chat rooms, bulletin boards or other means ("User Submissions"). User Submissions do not
        reflect the views of Visotsky Consulting Inc. ("Forum Moderators"), neither of whom has any obligation to
        monitor, edit or review any User Submissions on the Website.
      </p>
      <p>
        The forum moderators assume no responsibility or liability arising from the content of any user submissions, nor
        for any errors, slander, libel, defamation, omissions, lies, obscenity, pornography, profanity, danger or
        inaccuracies contained in any information in any user submission on Visotsky Consulting Inc. You are strictly
        prohibited from submitting or transmitting to the Forum Moderators any unlawful, threatening, libelous,
        defamatory, obscene, scandalous, inflammatory, pornographic or abusive material that would constitute or
        encourage conduct that would constitute a criminal offense, give rise to civil liability. liability or otherwise
        violate any law. Forum Moderators will fully cooperate with any law enforcement authorities or court order
        requiring or directing them to disclose the identity of or anyone posting any such information or materials.
      </p>
      <p>
        All user submissions will be treated as non-confidential and non-proprietary. Anything you submit or transmit to
        Visotsky Consulting Inc. or post is considered proprietary and may be used by the forum moderators for any
        purpose, including but not limited to reproduction, disclosure, transmission, communication, publication,
        broadcast and posting. In addition, forum moderators are free to use and be deemed to own any ideas, concepts,
        know-how or techniques contained in any user submissions you submit or transmit to Visotsky Consulting Inc. for
        any purpose whatsoever, including but not limited to developing, manufacturing and marketing products using such
        information. Disclosure, submission or offer of any User Submissions to Visotsky Consulting Inc. constitutes an
        assignment to the forum moderators of all right, title and interest worldwide in and to all copyrights and other
        intellectual property rights in such User Submissions.
      </p>
      <p>
        Forum Moderators may edit, copy, publish, distribute, translate and otherwise use in any medium any User
        Submissions that you submit or transmit to Forum Moderators, and will own exclusively all such rights, titles
        and interests and will not be restricted in any way from using the material, commercially or otherwise. The
        Forum Moderators have no obligation and will have no further obligation to maintain the confidentiality of any
        User Submissions, to compensate you or any other user for any User Submissions, or to respond to any of your
        User Submissions or those of other users.
      </p>
      <p>
        <b>No solicitation policy.</b> We have a strict no solicitation policy covering all our virtual and live events,
        ZOOM meetings, ZOOM conference calls, trainings, summits, training courses and exhibitions. To provide our
        attendees and guests with a distraction-free environment, we do not allow intrusive solicitation at any of the
        aforementioned events by attendees or unauthorized persons. This policy excludes, among other things, the
        following unauthorized activities at events organized by Visotsky Consulting Inc.
      </p>
      <p>
        <b>Prohibited Actions:</b>
      </p>
      <ul className="ml-5">
        <li>Unauthorized receptions and parties (including roadshows)</li>
        <li>Unauthorized exhibits, traveling exhibitions and hotel rooms</li>
        <li>Unauthorized membership recruitment activities</li>
        <li>Unauthorized subgroups in social networks (including WhatsApp)</li>
        <li>
          Unauthorized sale of any product or service not offered by an affiliate of Visotsky Consulting Inc. at this
          event.
        </li>
        <li>Unauthorized advertising of events not conducted by Visotsky Consulting Inc .</li>
        <li>Unauthorized employment</li>
        <li>Unwanted advances to event participants</li>
        <li>
          Unauthorized on-site distribution of printed or electronic materials, including literature, signs, flyers,
          invitations, tickets, letterhead, etc. (other than business cards)
        </li>
        <li>Unauthorized distribution of virtual invitations to any of the above prohibited events.</li>
        <li>
          Unauthorized use of facility Wi-Fi, guest Wi-Fi or company Wi-Fi for self-promotional or charitable purposes.
        </li>
        <li>Unauthorized use of the venue for self-promotional or charitable purposes.</li>
      </ul>
      <p>
        Any person and/or organization found to be in violation of this policy will be excluded from the event without
        refund and may be subject to legal action for any damages suffered by Us or other event attendees.
      </p>
      <p>
        Exhibitors found to have violated these prohibited acts agree to pay the affected company an amount equivalent
        to the sponsor or exhibitor fee and a prorated portion of the cost of the event. Attendees found in violation of
        this policy will also be immediately expelled from the event and disqualified from future events at the
        discretion of Visotsky Consulting Inc.
      </p>
      <p style={{ textIndent: "3em" }}>
        <b>VIDEO AND PHOTOGRAPHY</b>
      </p>
      <p style={{ textIndent: "3em" }}>
        <b>PHOTOGRAPHY AND VIDEO OR AUDIO RECORDING ARE ALLOWED UPON AGREEMENT WITH VISOTSKY CONSULTING INC.</b>
      </p>
      <p style={{ textIndent: "3em" }}>
        <b>WE DO NOT AUTHORIZE VIDEO OR AUDIO RECORDINGS OF OUR EVENTS FOR DISTRIBUTION OR COMMERCIAL USE.</b>
      </p>
      <p>
        You are not permitted to videotape or "live stream" on social media during this event for reasons of privacy and
        respect for others, other attendees, venue staff, employees and any member of the Visotsky Consulting Inc. team.
        You are permitted to use photo or video materials in order to disseminate information about Visotsky Consulting
        Inc. events, however, such materials may not be commercialized.
      </p>
      <p>
        Amateur or professional videotaping, photography or audio equipment is not permitted on the premises and any
        participant violating this rule may be ejected from the venue without re-entry and their tickets will be
        forfeited.
      </p>
      <p>
        Visotsky Consulting Inc. may PR your results in its social networks, websites, informational materials, as well
        as in offline events, using reviews, photos and videos that you leave on the official pages of Visotsky
        Consulting Inc., in messengers or when communicating with representatives of Visotsky Consulting Inc. This
        condition is valid from the date of purchase of any product or service and applies to any items created by
        Visotsky Consulting Inc. during the period of your access to the Platform, as well as received from you during
        this period. Consent to the above may be withdrawn by you at any time by writing to Visotsky Consulting Inc.
      </p>
      <p>
        By purchasing any product or service from Visotsky Consulting Inc. you agree to receive advertising and
        information material from Visotsky Consulting Inc. and its partners in order to possibly receive various
        incentives (bonuses, discounts) from them. The conditions of receipt and the amount of incentives are determined
        by Visotsky Consulting Inc. and its partners independently. In case of changes in the terms of incentives
        Visotsky Consulting Inc. has the right to change, add and remove bonuses from partners, sending a written notice
        to the client to the email specified in myAlpari. In case of such changes, you have no right to demand from
        Visotsky Consulting Inc. to leave the previous conditions of bonuses and you have no right to demand any
        payments and compensation of losses related to such changes. You understand and accept that Visotsky Consulting
        Inc. does not independently make any changes in the activities of partners and is not responsible for and does
        not control such changes. This consent may be withdrawn by you at any time by writing to Visotsky Consulting
        Inc.
      </p>
      <p>
        Only members of the press who have been invited or requested entry prior to the event and have provided verified
        credentials will be allowed entry. Any member of the press authorized to enter must wear a press badge at all
        times while on the premises of the venue and must be prepared to show their credentials when asked to do so.
        Only press members on the press list will be allowed entry. Our offline events are private events and Visotsky
        Consulting Inc. reserves the right, in its sole discretion, to refuse admission to any participant for any
        reason without explanation. Each participant in such an event must abide by the rules and regulations of the
        venue, and any violation of these rules and regulations by an event participant or event organizer will result
        in that person being ejected from the premises without the right to re-enter and without refund of ticketed
        tickets.
      </p>
      <p>
        <b>User Content Guidelines.</b> The following terms and conditions apply to the content you submit: The Websites
        may contain comment sections, discussion forums or other interactive features ("Interactive Areas") where you
        may post or upload user content, comments, videos, photos, messages, other materials or items (collectively,
        "User Content"). You are solely responsible for and use any Interactive Areas at your own risk. Interactive
        Areas are available to persons 13 years of age and older. By submitting User Content to an Interactive Area, you
        represent that you are 13 years of age or older and, if you are under 18 years of age, you are either an
        emancipated minor or have obtained the legal consent of your parent or legal guardian to accept these Terms and
        Use, submit content and participate on the Websites. By attending any Event, you hereby irrevocably grant
        Visotsky Consulting Inc., affiliates, assigns, successors and licensees the right to film and otherwise record
        you and to use your name, image and likeness in any media for any purpose whatsoever, including but not limited
        to promotional or marketing purposes, and as part of or in connection with our future events and/or other events
        hosted by Visotsky Consulting Inc. or any of Visotsky Consulting Inc.'s subsidiaries, and hereby release
        Visotsky Consulting Inc. and each of its respective assigns, successors, licensees and affiliates from any and
        all liability with respect thereto.
      </p>
      <p>
        By submitting any User Content or participating in the Interactive Area on or in connection with the Sites, you
        agree that you will not upload, post or otherwise transmit any User Content that (a) in any way violates or
        infringes upon the rights of others; including any statements that would defame, harass, stalk or threaten
        others; (b) you know to be false, misleading or inaccurate; (c) contains blatant bigotry, racism, racially or
        ethnically offensive content, hate speech, abusive content, vulgarity or profanity; (d) contains or promotes
        pornography or sexually explicit content, pedophilia, incest, bestiality or is otherwise obscene or indecent;
        (e) violates any law or protects or instructs in dangerous, illegal or predatory acts or discusses illegal acts
        with the intent to commit them; (f) promotes violent behavior; (g) poses a reasonable threat to personal or
        public safety; (h) contains violent depictions of murder or physical violence that appear to have been taken
        solely or primarily for exploitative, lustful or gratuitous purposes; (i) is protected by copyright, trademark,
        trade secret, right of n The burden of determining that any User Content is not protected by copyright,
        trademark, trade secret, right of publicity or other proprietary rights rests with you. You are solely
        responsible for any damages resulting from infringement of copyrights, trademarks, trade secrets, rights of
        publicity or other proprietary rights, and for any other damages resulting from such submission. Any person that
        Visotsky Consulting Inc. in its sole discretion determines has infringed the intellectual property or other
        rights of others will be prohibited from submitting or posting any additional material on the Websites; (j) is
        not generally related to the designated topic or subject matter of any Interactive Area; (k) contains any
        unsolicited or unauthorized advertising or promotional material regarding products or services, "junk mail,"
        "spam," "chain letters," "pyramid schemes," or any other form of solicitation; (j) is not generally related to
        the designated topic or subject matter of any Interactive Area; or (k) contains any unsolicited or unauthorized
        advertising or promotional material regarding products or services, "junk mail," "spam," "chain letters,"
        "pyramid schemes," or any other form of solicitation. The burden of determining that any User Content is not
        protected by copyright, trademark, trade secret, right of publicity or other proprietary rights rests with you.
        You are solely responsible for any damages resulting from infringement of copyrights, trademarks, trade secrets,
        rights of publicity or other proprietary rights, and for any other damages resulting from such submission. Any
        person that Visotsky Consulting Inc. has determined, in its sole discretion, to have infringed the intellectual
        property or other rights of others will be prohibited from submitting or posting any additional material on the
        Websites.
      </p>
      <p>
        You agree not to upload, post or otherwise transmit any User Content, software or other material that contains a
        virus or other malicious or destructive component.
      </p>
      <p>
        You agree not to use any technology, services or automated systems to publish more User Content than a person
        can upload in a given period of time. You also agree not to authorize third parties to use these services,
        technologies or automated systems on your behalf. Any behavior that, in our sole discretion, we believe
        restricts or inhibits the use of the Websites by anyone else will not be permitted. We reserve the right, in our
        sole discretion, to remove or edit User Content you provide.
      </p>
      <p>
        We are not responsible for the accuracy or reliability of any User Content and accept no responsibility for any
        actions you may take as a result of reading User Content posted on the Websites. By using the Interactive Areas,
        you may be exposed to content that you may find offensive, objectionable, harmful, inaccurate or misleading.
        There may also be risks of communicating with minors, people acting under false pretenses, international trade
        issues, and foreign nationals. By using interactive areas, you assume all associated risks. We have the right,
        but not the obligation, to monitor User Content posted or uploaded to the Websites to determine compliance with
        these Terms of Use and any operating rules we establish, and to satisfy any law, regulation or authorized
        governmental request. While we are under no obligation to monitor, review, edit or remove any User Content
        posted or uploaded to the Sites, we reserve the right and in our absolute discretion to review, edit, refuse to
        post or remove without notice any User Content. posted or uploaded to the Sites at any time and for any reason,
        and you are solely responsible for backing up and replacing any User Content posted to the Sites, for va
        Visotsky Consulting Inc.'s decision to monitor and/or modify User Content does not constitute, and shall not be
        deemed to constitute, any liability or responsibility on our part in connection with your use of the Interactive
        Areas on the Sites.
      </p>
      <p>
        By submitting User Content to the Websites, you automatically grant us a royalty-free, perpetual, irrevocable,
        non-exclusive, worldwide right and license to use, publish, reproduce, modify, alter, adapt, edit, translate,
        create derivative works, incorporate into other works, distribute, sublicense (through multiple tiers) and
        otherwise exploit such User Content (in whole or in part) in any form, media or technology now known to exist In
        addition, to the maximum extent permitted by applicable law, you waive your moral rights in the User Content and
        agree not to assert such rights against us. You represent and warrant to us that you have full legal right,
        power and authority to grant us the license contemplated herein, that you own or control all exhibition and
        other rights to the User Content that you have granted for the purposes contemplated herein, the license, and
        that neither the User Content nor the exercise of the rights granted herein shall violate these Terms or any
        rights, including the right of privacy or right of publicity, and shall not You also grant us the right, but not
        the obligation, to prosecute any person or entity that violates your or our rights in User Content by violating
        these Terms.
      </p>
      <p>
        <b>User Behavior Guidelines.</b> The following terms and conditions apply to your conduct in accessing or using
        the Websites: (a) you agree not to interfere with or disrupt the Websites or the servers or networks connected
        to the Websites, or to comply with any requirements, procedures, policies or rules of the connected networks. to
        the Websites; (b) you agree not to reproduce, duplicate, copy, sell, resell or exploit for any commercial
        purpose any part of the Websites, use of the Websites or access to the Websites; (c) you agree not to
        participate in any other commercial activity on the Websites; (d) you agree not to use the Websites for any
        other purpose; and (e) you agree not to use the Websites for any other purpose.
      </p>
      <p>
        <b>Live Events.</b> If you attend one of our live events, you will be a participant in an event that is live
        streamed and recorded. In addition, photos and video clips taken by Visotsky Consulting Inc. and any other
        outside participants such as speakers and sponsors will be available throughout the event. Therefore, the
        purchase of any live event ticket is subject to the fact that it also contains a DISCLAIMER OF LIABILITY and
        that you agree to the following:
      </p>

      <p style={{ textIndent: "3em" }}>
        <cite>
          I irrevocably authorize Visotsky Consulting Inc. and all of its affiliated companies to use my image in
          photographs, video or other digital media ("photo") in any and all of their publications, including Internet
          publications, without payment or other consideration. I waive any right to review or endorse a finished
          product that contains my image. In addition, I waive any right to royalties or other compensation arising from
          or related to the use of my photographs. My comments or reviews will not be edited. I acknowledge that no
          compensation will be given for my review. I understand and agree that all my photos and videos will become the
          property of Visotsky Consulting Inc. and all affiliated companies and will not be returned.
        </cite>
      </p>
      <p style={{ textIndent: "3em" }}>
        <cite>
          I hereby indemnify, release and forever discharge Visotsky Consulting Inc. and all of its affiliates from all
          claims, demands and causes of action that I, my heirs, representatives, agents, executors, administrators or
          any other person acting on my behalf or on behalf of me have or may have under this authorization. If my
          reviews or comments are cut or not used, I agree that this was at the creative discretion of Visotsky
          Consulting Inc. and all of its subsidiaries and/or its affiliates, and that I will take no legal action. I
          further agree that any claims will be arbitrated through the American Arbitration Association and that the
          jurisdiction for all claims is New York, NY.
        </cite>
      </p>
      <p style={{ textIndent: "3em" }}>
        <cite>
          I hereby DISCLAIM all claims arising out of or in connection with such use of Visotsky Consulting Inc. and all
          affiliated companies, including, without limitation, all claims for defamation or invasion of privacy. I have
          read this Disclaimer and am fully familiar with its contents. This Disclaimer contains the entire agreement
          between the parties and supersedes any other agreements that may exist.
        </cite>
      </p>
      <p>
        Please note that we cannot provide you with an invitation letter or any other immigration documentation to help
        you obtain a visa to travel to the United States. We advise international buyers to obtain a travel visa before
        purchasing a ticket to a live event. If you live in a country that prohibits travel to the United States, we
        will not be able to assist with any documentation. As a general rule, no refunds will be given if you are absent
        from the Event. Refund policies in the event of a canceled Event are determined by Visotsky Consulting Inc.
        separately.
      </p>

      <h2>You're being searched</h2>
      <p>
        At any offline Event, we are required to comply with all venue rules regarding searches and items that are and
        are not allowed into the venue. Upon entering the Event, you and your belongings may be searched. You consent to
        such search and waive any related claims that may arise. If you choose not to consent to such searches, you may
        be denied entry to the Event without refund or other compensation. Certain items, including, but not limited to,
        firearms, alcohol, drugs, controlled substances, cameras, recording devices, laser pointers, strobe lights,
        stimuli (e.g., artificial noise generators), bundles and containers, may not be brought onto the premises in
        accordance with certain Event facility rules.
      </p>
      <p>
        The use of laptops, tablets or any computers may be prohibited at the Event venues. This is because we are
        enforcing the rules of the venue. Backpacks and large bags are also not allowed. We reserve the right to refuse
        entry to any person who attempts to enter the venue with any prohibited or restricted items.
      </p>

      <h2>No animals are allowed unless it is a registered service dog; Emotional support animals are not allowed.</h2>
      <p>
        Accommodations with animals are not permitted unless it is a registered service dog. The Americans with
        Disabilities Act (ADA) defines a service animal as a dog that is individually trained to perform tasks or do
        work for the benefit of a person with a disability. The tasks or work the animal performs must be directly
        related to the person's disability.
      </p>

      <h2 style={{ textIndent: "3em" }}>
        PLEASE NOTE THE FOLLOWING NOTICES REGARDING ALL LIVE EVENTS AND GUEST SPEAKER EVENTS
      </h2>

      <h2 style={{ textIndent: "3em" }}>
        Please note the start time of the event, as once the doors to the event hall are closed, they cannot be opened
        until the next segment begins. No one will be allowed to enter the hall during active speakers for safety
        reasons and there will be no exceptions, regardless of the circumstances. Therefore, latecomers will have to
        wait until the break between speakers to take their seat.
      </h2>

      <h2 style={{ textIndent: "3em" }}>
        You must have a valid badge issued for the Event in order to enter the Event area. If you have lost your badge,
        please contact the Venue Help Desk for information on how to obtain a new badge. New badges will be issued for a
        fee of $150.00.
      </h2>

      <h2 style={{ textIndent: "3em" }}>Illegal resale or transfer of Tickets; Shares</h2>
      <p>
        <b>
          Illegal resale (or attempted resale) of any tickets is grounds for confiscation and forfeiture of the ticket
          and refusal to refund. In addition, unlawful transfer (or attempted transfer) of any tickets is grounds for
          revocation and forfeiture.
        </b>{" "}
        Certain states may apply certain maximum resale premiums and restrictions, and we cannot be responsible for
        providing legal advice regarding resale or ticket pricing. You are responsible for complying with all applicable
        ticket resale laws. In addition, we reserve the right to restrict or deny the right to purchase tickets to
        anyone we believe is or has been in violation of our policies. Because we do not guarantee the authenticity of
        tickets purchased from unauthorized third-party resellers (such as brokers or individuals), we recommend that
        you purchase tickets directly through us, authorized partners or licensees to ensure the authenticity of the
        tickets. Tickets may not be used for advertising, promotions, contests or sweepstakes without our official
        written authorization.
      </p>
      <p>
        Any counterfeit or altered tickets will be immediately confiscated without compensation and entry to the offline
        Event will be denied.
      </p>
      <p>
        <b>Tickets and age requirements for offline Events.</b> To participate in any Event, you must have your own
        valid Ticket. Each participant requires one Ticket, which is valid only on the day(s) of the Event and in the
        area(s) of the Event specified on the Ticket. Entry to the Event is limited to persons who meet the minimum age
        requirements on or before the day of the Event. You must be at least eighteen (18) years of age or older to
        enter the Event, unless the specific Event specifies that children are allowed. All children must have their own
        Ticket and will be denied access if they do not have a ticket purchased in their name.
      </p>
      <p>
        Persons under the age of 18 are not permitted to enter the event, and you will be denied access if you are
        accompanied by a child. This includes children in strollers, portable car seats, and diaper bags, backpacks and
        strollers. Any live event arena will have its own set of rules that the Company must abide by.
      </p>
      <p>
        You must have one of the following forms of age-approved identification to participate in the Event. There will
        be no exceptions, even if you are accompanied by an adult or person with valid ID. Failure to provide valid
        identification will prevent you from participating in the Event and you will not be entitled to any refund, nor
        will you be entitled to or liable for any other damages, including but not limited to incidental and/or
        unforeseen damages. or consequential damages. In addition, you must provide valid identification upon request by
        a member of security or other personnel during the Event. If you are unable to produce valid identification, you
        may be immediately ejected from the Event and you will not receive a refund for your Ticket.
      </p>
      <p>
        The following original forms of ID are acceptable and your name must appear on the ticket exactly as it appears
        on your ID card:
      </p>
      <p style={{ textIndent: "3em" }}>
        - A government-issued passport with photo; - A government-issued driver's license with photo; - A military ID
        with photo (U.S. or Canada); - A military ID with photo (U.S.); or - Your country's government-issued ID with
        photo.
      </p>
      <p>No other forms of identification will be accepted.</p>
      <p>
        Visotsky Consulting Inc. may determine special rules for attending Events and requirements for participants,
        which may be determined by the specific program of the relevant Event, which may be posted in booklets, other
        promotional and informational materials, as well as websites of Visotsky Consulting Inc. or our affiliated
        companies.
      </p>
      <p>
        <b>NO GUARANTEES OF OUTCOME.</b> We do not guarantee results and do not offer legal advice. Entrepreneurship and
        human resources management, forecasting financial investments always involve risk and hard work. Always consult
        your own experts. Any feedback attesting to our success or the success of our students should not be interpreted
        as normal, typical or expected. Some feedback is from our clients who are now working as coaches or trainers for
        Visotsky Consulting Inc. Achieving your goals requires education, determination and hard work.
      </p>

      <h2>MAIN PROVISIONS</h2>
      <p>
        <b>Jurisdiction.</b> This Agreement shall be governed by and construed in accordance with the laws of the State
        of New York without regard to conflict of laws principles. In the event of any dispute relating to these Terms,
        the Site or the Materials, you consent to the exclusive jurisdiction of the federal and state courts located in
        the State of New York and waive any objection to jurisdiction, venue or inconvenient forum in such courts. The
        Site is controlled and operated by Visotsky Consulting Inc. from its principal place of business in New York, NY
        and is not intended to be subject to the laws or jurisdiction of any state, country or territory other than the
        State of New York and the United States of America.
      </p>
      <p>
        Visotsky Consulting Inc. does not represent or warrant that the Website or any aspect of the Website is
        appropriate or available for use in any particular jurisdiction. Those who choose to access the Website are at
        their own risk and are responsible for compliance with local laws.
      </p>
      <p>
        We may restrict the availability of the Website to any person, geographic area or jurisdiction of our choice at
        any time at our sole discretion. You agree not to transport, import, export or re-export any content on the Site
        to a citizen or resident of any of the following countries: (i) the Balkans, Burma (Myanmar), Cuba, Iran,
        Liberia, North Korea, Sudan, Syria, or any other country on whose goods the United States has embargoed; or (ii)
        any person or entity listed on the U.S. Treasury Department's list of Special Category Nationals or the U.S.
        Commerce Department's Denial of Orders Table, and you represent, warrant and agree with us that you are not
        located in or under the control of any such country or on any such list. If any provision of these Terms is for
        any reason held to be illegal, invalid or unenforceable, that provision will be deemed separate from these Terms
        and will not affect the validity and enforceability of any remaining provision. Any heading, caption or section
        title contained herein is inserted for convenience only and in no way defines or explains any section or
        provision hereof. The failure of a party to insist upon or enforce strict performance of any provision of these
        Terms and Use shall not be construed as a waiver of any provision or right. This, together with any of our
        policies referred to herein, constitutes the entire agreement between you and Visotsky Consulting Inc. relating
        to your use of the Website; these Terms supersede all prior or contemporaneous written or oral agreements
        between you and Visotsky Consulting Inc. regarding the same subject matter (except for other written, fully
        executed contracts between you and us). Neither the conduct between you and us nor trade practices may modify
        any provision of these Terms. These Terms are not assignable, transferable or sublicensable by you without our
        prior written consent.
      </p>
      <p>*All digital products are not covered by the 100% money back guarantee and are non-refundable.</p>
      <p>* Offerings may not be used in conjunction with each other unless otherwise specified.</p>

      <h2>Account Status</h2>
      <p>
        If your account is in arrears or is not in good standing for any reason, any special offers, flash sales, deals,
        bonuses, purchase gifts, coupons, discounts and incentives are not available for use.
      </p>
      <p>
        Please remember to return to these Terms and Use periodically to review the most current version. We reserve the
        right to change or otherwise modify these Terms at any time in our sole discretion without notice; however, the
        date of any effective change will be indicated at the top of this page and we will provide you with information
        about any changes upon request.
      </p>
      <p>
        <b>Electronic Communications.</b> When you visit the Website or send emails to us, you are communicating with us
        electronically. You agree to receive communications from us electronically. We will communicate with you by
        email or by posting notices on the Website. You agree that all agreements, notices, disclosures and other
        communications that we provide to you electronically satisfy all legal requirements that such communications be
        in writing.
      </p>
      <p>
        We may discontinue the Website at any time and for any reason without notice. We may change the content,
        operation or features of the Website at any time and for any reason without notice.
      </p>
      <p>
        You agree that no joint venture, partnership, employment or agency relationship exists between you and us as a
        result of these Terms of Use or your use of the Website. Nothing contained in these Terms of Use is in
        derogation of our right to comply with requests or requirements of governmental, judicial and law enforcement
        authorities relating to your use of the Website or information provided or collected by us with respect to such
        use. A printed version of these Terms of Use and any notice provided in electronic form shall be admissible in
        judicial or administrative proceedings based on or related to these Terms of Use to the same extent and subject
        to the same conditions as other business documents and records originally created and maintained in printed
        form.
      </p>
      <p>
        Our failure to enforce any provision of these Terms of Use or to respond to a breach by you or others shall not
        constitute a waiver of our right to enforce any other provision of these Terms of Use with respect to that or
        any other breach.
      </p>
      <p>
        If any provision of these Terms of Use is invalid or unenforceable under applicable law, the remaining
        provisions shall remain in full force and effect, and the invalid or unenforceable provision will be deemed
        superseded by a valid, enforceable provision that most closely matches the intent of the original provision.
      </p>
      <p>
        These Terms of Use constitute the entire agreement between you and us with respect to the Website and supersede
        any prior or contemporaneous agreement with respect to that subject matter.
      </p>
      <p>
        <b>
          By purchasing any service, product or event on this website, you acknowledge and agree to be bound by the
          terms set forth in these{" "}
          <a target="_blank" href="/doc/terms-of-use">
            Terms of Use
          </a>
          , as well as the{" "}
          <a target="_blank" href="/doc/terms-of-payment-and-refunds">
            Terms of payment and refunds
          </a>{" "}
          and{" "}
          <a target="_blank" href="/doc/privacy-policy">
            Privacy Policy
          </a>
          . If you do not agree to these Terms, please do not purchase anything or enter into any transaction with us.
        </b>
      </p>
      <p>
        Be sure to return to these Terms periodically to review the most current version. We reserve the right to change
        or otherwise modify these Terms at any time in our sole discretion without notice; however, the date of any
        effective changes will be indicated at the bottom of this page, and we will provide you with information about
        any changes upon request.
      </p>
    </div>
  );
};
