import React from "react";
import "./DocPage.scss";

export const CookiesPolicyPage = () => {
  return (
    <div className="document__wrapper">
      <h1>COOKIES POLICY</h1>
      <h3>These Policy were last updated on October 01, 2023</h3>
      <p>
        We use cookies and similar technologies on this Website (hereinafter referred to as the "Website"). In this
        Cookies Policy we will tell you about the different types of cookies and similar technologies that are used
        here.
      </p>
      <p>
        We, Visotsky Consulting inc. are processing of Personal Data. This means that we process Personal Data and
        determine the purposes of this processing and what data we process. Our address is 368 9th Ave 6FL New York, NY
        10001.
      </p>
      <p>
        We may change the Cookies Policy from time to time. Once we post an updated version of the Policy on or through
        the Website, all changes will take effect.
      </p>
      <p>
        If you have questions or need to contact us about the Cookies Policy, you can email us at:{" "}
        <a href="mailto:support@visotsky.us" children="support@visotsky.us" />.
      </p>
      <p>
        All terms and definitions you will find in this Cookies Policy have the same meaning as in our Privacy Policy.
        You can read it{" "}
        <a target="_blank" href="/doc/privacy-policy">
          here
        </a>
        .
      </p>

      <h2>1. Cookies and similar technologies</h2>
      <p>Let's understand what cookies and similar technologies are and how they interact with our Website.</p>
      <p>
        Cookies are a standard feature of websites that allow us, as the Personal Data Operator, to store small portions
        of data on your computer about your visit to the Website. We use cookies, which are small text files that are
        placed on your computer's hard drive. We also use other technologies to help us obtain information, such as what
        content you are interested in on the Website and how much time you spend on different parts of the Website.
      </p>
      <p>
        It is important to note that cookies are only read by our servers and are not capable of running any code or
        viruses. We use this information to provide security, improve navigation, present information more effectively,
        and create a personalized experience for you when using the Website. We also use this data to analyze your
        activities on the Website to improve its design and functionality, to better understand how you use it, and to
        help you with possible technical issues.
      </p>
      <p>
        It is important to know that if you disable cookies or similar technologies, this may limit your interaction
        with the Website. Some features may not work as we intended.
      </p>
      <p>
        We value your interaction with our Website, and cookies and similar technologies help make your experience more
        convenient and rich.
      </p>

      <h2>2. The types of cookies (and similar technologies) used on the Website.</h2>
      <p>
        Let's take a look at the different types of cookies and similar technologies we use on the Website. These
        technologies help us better understand your experience and provide you with better interactions.
      </p>
      <p>
        <b>Basic Cookies:</b> These cookies are sent directly to your device. They allow us to better understand how you
        interact with the Website, such as which sections you navigate to and how much time you spend on each page.
      </p>
      <p>
        <b>Third Party Cookies:</b> These cookies are provided by third parties on our behalf. We use them to analyze
        your use of the Website and for personalized advertising. They help us to understand what aspects of the Website
        are of interest to users and to deliver advertising relevant to your interests.
      </p>
      <p>
        <b>Required cookies:</b> These cookies are necessary to allow you to move around the Website and use its
        features. Without them, the Website will not function consistently and may not be able to provide you with
        certain services.
      </p>
      <p>
        <b>Preference Cookies:</b> These cookies help us remember your preferences, such as language settings or local
        preferences. Through these files, we can customize the Website for you to provide you with a more personalized
        experience.
      </p>
      <p>
        <b>Social Media Cookies:</b> These cookies collect information about your use of social media. We use them to
        integrate sharing tools between the Website and social media, and to measure the effectiveness of these tools.
      </p>
      <p>
        <b>Analytical Cookies:</b> These cookies allow us to analyze your interaction with the Website. We can see which
        pages are most frequently visited and can improve the design and functionality of the Website based on this
        information. They also allow us to analyze usage of the Website as a whole, without specifically identifying
        individual users.
      </p>
      <p>
        <b>Advertising Cookies:</b> Advertising cookies are used to display advertisements to you that match your
        interests on the Website. They also help us manage the placement of advertisements. These cookies collect
        information about your activities on the Website to provide you with targeted advertising.
      </p>
      <p>
        <b>Browser or Device Information:</b> We automatically receive certain information about your browser or device,
        such as your computer type (Windows or Mac), screen resolution, operating system, device model, and other
        characteristics. This information allows us to ensure that the Website works properly and is customized to your
        device.
      </p>
      <p>
        <b>Google Analytics:</b> We use Google Analytics to analyze the use of our services and report on user activity.
        This information allows us to improve the Website and services. Google Analytics may also collect information
        about the use of other websites and resources. You can learn more about Google Analytics and opt-out by
        downloading a browser add-on.
      </p>
      <p>
        <b>Physical Location:</b> We may collect information about your physical location through satellite, Wi-Fi or
        cellular signals. This allows us to provide you with personalized content based on your location. We may also
        use your location information to analyze the effectiveness of advertising campaigns.
      </p>
      <p>
        <b>Carrot Quest:</b> This is a service that includes eCRM tools, email newsletters, pop-ups, online chat and web
        analytics. We use this service to make the online chat on the Website work properly and provide you with a
        better interaction experience. We do not collect or process personal data through Carrot Quest.
      </p>
      <p>
        <b>Hotjar:</b> This service allows us to study anonymous user activity on the Website. We can see what actions
        users take, and we can also create heat maps showing which parts of the Website generate the most interest. This
        helps us understand how much information about our services is of interest to users and how they interact with
        the Website.
      </p>
      <p>
        <b>Indexed DB:</b> We use Indexed DB technology to store the keys/values you enter on the Website. This allows
        us to manage a database of JSON objects. This information helps us provide you with a more personalized
        experience.
      </p>

      <h2>3. Managing cookies</h2>
      <p>
        You have the right to refuse or accept cookies from the Website at any time by adjusting the appropriate
        settings on your browser. Instructions on how to enable or disable cookies can be found on the Website of your
        Internet browser provider. Please note that disabling cookies may affect the functionality of the Website and
        may result in some inconvenience in use.
      </p>
      <p>
        If you plan to delete all cookies left by websites you have visited, there are third-party tools available for
        this purpose. If you need more information, please contact your browser's support team.
      </p>
      <p>Remember that your participation in cookie management helps you control your online experience.</p>
    </div>
  );
};
