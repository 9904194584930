import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  VStack,
} from "@chakra-ui/react";
import { TBuyDialog } from "../../../../pages/IndexPage";
import { IIndexPageBuyDialogState } from "./NewPageBuyDialog";
import { Field, Formik } from "formik";
import * as Yup from "yup";

interface IIndexPageBuyDialogView {
  isOpen: boolean;
  type: TBuyDialog;
  state: IIndexPageBuyDialogState;
  isLoading: boolean;
  isSuccess: boolean;

  setState: (state: IIndexPageBuyDialogState) => void;
  onSubmitForm: () => void;
  onClose: () => void;
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function NewPageBuyDialogView(props: IIndexPageBuyDialogView) {
  const formRef = useRef(null);
  const [isAgree, setIsAgree] = useState<boolean>(false);

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Name is a required field"),
    email: Yup.string().email("Make sure your email is entered without errors").required("Email is a required field"),
    phoneNumber: Yup.string().required("Phone Number is a required field"),
  });

  const handleFormikSubmit = () => {
    // @ts-ignore
    formRef.current && formRef.current.handleSubmit();
  };

  useEffect(() => {
    if (!props.isOpen) {
      // @ts-ignore
      formRef.current && formRef.current.resetForm();
      setIsAgree(false);
    }
  }, [props.isOpen]);

  return (
    <Modal closeOnOverlayClick={!props.isLoading} isOpen={props.isOpen} isCentered onClose={props.onClose}>
      <ModalOverlay />
      {props.isSuccess ? (
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton disabled={props.isLoading} />
          <ModalBody>
            <Alert
              style={{ borderRadius: "8px" }}
              className="mt-1"
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Thanks!
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                We have received your application and will contact you soon.
              </AlertDescription>
            </Alert>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      ) : (
        <ModalContent>
          <ModalHeader>
            <Heading as="h5" fontSize="24px">
              {props.type == "vip" ? "Turnkey implementation" : "Choose a plan"}
            </Heading>
            {props.type == "vip" && (
              <Text className="my-2" lineHeight="1.15em" fontWeight="normal" color="#909090" fontSize="16px">
                Enter your details to submit a request for the selected tariff
              </Text>
            )}
          </ModalHeader>
          <ModalCloseButton disabled={props.isLoading} />
          <ModalBody>
            <Formik
              innerRef={formRef}
              initialValues={{ fullName: "", email: "", phoneNumber: "" }}
              validationSchema={validationSchema}
              onSubmit={(v) => {
                props.setState({
                  ...props.state,
                  name: v.fullName,
                  phoneNumber: v.phoneNumber,
                  email: v.email,
                });
                props.onSubmitForm();
              }}
            >
              <VStack spacing="16px">
                <Field
                  name="fullName"
                  component={({ field, form: { touched, errors } }: any) => (
                    <FormControl isInvalid={touched[field.name] && errors[field.name]}>
                      <FormLabel>Full Name</FormLabel>
                      <Input autoComplete="off" placeholder="Please enter your full name" size="lg" {...field} />
                      <FormErrorMessage>{errors[field.name]}</FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  name="email"
                  component={({ field, form: { touched, errors } }: any) => (
                    <FormControl isInvalid={touched[field.name] && errors[field.name]}>
                      <FormLabel>Email</FormLabel>
                      <Input autoComplete="off" placeholder="Please enter your email" size="lg" {...field} />
                      <FormErrorMessage>{errors[field.name]}</FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  name="phoneNumber"
                  component={({ field, form: { touched, errors } }: any) => (
                    <FormControl isInvalid={touched[field.name] && errors[field.name]}>
                      <FormLabel>Phone Number</FormLabel>
                      <Input autoComplete="off" placeholder="Please enter your phone number" size="lg" {...field} />
                      <FormErrorMessage>{errors[field.name]}</FormErrorMessage>
                    </FormControl>
                  )}
                />
              </VStack>
            </Formik>
            {(props.type == "standard" || props.type == "premium") && (
              <div>
                <div className="d-flex justify-space-between full-width mt-6" style={{ fontSize: "14px" }}>
                  {/*, background: "#ffffe0"*/}
                  <span>Standard Implementation Program</span>
                  <span>$2,490</span>
                </div>
                <Divider className="my-4" />
                <div className="d-flex justify-space-between full-width" style={{ fontSize: "14px" }}>
                  <span>System access fee (up to 10 employees) 30 days</span>
                  <span>$290</span>
                </div>
                <div className="d-flex justify-space-between align-center mt-4">
                  <Checkbox
                    checked={props.state.extraEmployees}
                    onChange={(e) => props.setState({ ...props.state, extraEmployees: e.currentTarget.checked })}
                    style={{ fontSize: "14px" }}
                  >
                    <span style={{ fontSize: "14px" }}>Extra employees</span>
                  </Checkbox>
                  <div className="d-flex">
                    <NumberInput
                      size="xs"
                      maxW={20}
                      inputMode="numeric"
                      pattern="\d*"
                      min={1}
                      value={String(props.state.extraEmployeesAmount ?? 1)}
                      onChange={
                        (e) =>
                          props.setState({
                            ...props.state,
                            extraEmployeesAmount: (e ?? "").trim().length > 0 ? Number(e) : 0,
                          })
                        //   props.setState({
                        //   ...props.state,
                        //   extraEmployeesAmount: e.split("").some((d) => ["e", "E", "+", "-"].includes(d))
                        //   ? props.state.extraEmployeesAmount
                        //   : (e ?? "").trim().length > 0
                        //   ? Number(e)
                        //   : 0,
                        // });
                      }
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <span className="ml-2" style={{ fontSize: "14px" }}>
                      x $29
                    </span>
                  </div>
                </div>
                <Divider className="my-4" />
                <div style={{ fontSize: "14px" }}>
                  <span>Total amount: </span>
                  <span style={{ fontWeight: "bold" }}>{currencyFormatter.format(props.state.totalCost)}</span>
                </div>
                <Divider className="mt-4" />
              </div>
            )}
            <Checkbox
              className="mt-4"
              isChecked={isAgree}
              onChange={(e) => setIsAgree(e.target.checked)}
              children={
                <span style={{ fontSize: "14px" }}>
                  <span children="I hereby give my " />
                  <Link
                    href="/doc/personal-data-processing-agreement"
                    isExternal
                    children="Consent to the processing of personal data"
                    style={{
                      color: "var(--color-primary-base)",
                    }}
                  />
                  <span children=" in accordance with the " />
                  <Link
                    href="/doc/privacy-policy"
                    isExternal
                    children="Privacy Policy"
                    style={{
                      color: "var(--color-primary-base)",
                    }}
                  />
                  <span children=" and the " />
                  <Link
                    href="/doc/terms-of-use"
                    isExternal
                    children="Terms of Use"
                    style={{
                      color: "var(--color-primary-base)",
                    }}
                  />
                </span>
              }
            />
          </ModalBody>
          <ModalFooter>
            {/*props.onSubmitForm*/}
            <Button
              disabled={!isAgree}
              isLoading={props.isLoading}
              onClick={() => handleFormikSubmit()}
              className="flex-grow-1"
              height="48px"
              colorScheme="red"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
}
