import React from "react";
import { Center, GridItem, Heading, Image, SimpleGrid, Text } from "@chakra-ui/react";
import "./IndexPageBlock4.scss";

const blocksData = [
  {
    id: 1,
    title: "Create a functional organizational structure and describe all functions",
    description:
      "Establish an organizational structure and describe all functions in detail.  Allocate roles to employees and define their deliverables.",
  },
  {
    id: 2,
    title: "Delegate task control",
    description:
      "Enter all tasks into the platform and define performance criteria for each task. Start working on the tasks on the platform.Enter all tasks into the platform, defining their performance criteria. Connect with a specially trained person - Task Driver. Task Driver is our person who monitors the performance of tasks by your employees.",
  },
  {
    id: 3,
    title: "Free up your time and focus on strategic tasks",
    description:
      "When all functions are scheduled, repetitive tasks are performed, and Task Driver is connected, monitoring 100% task completion - you  phase out from the routine  and focus on strategic development. You have full control over the performance of current tasks and any new ones that might appear in development.",
  },
];

export function IndexPageBlock4() {
  return (
    <div className="indexPageBlock4 __block-wrapper" style={{ background: "var(--color-tertiary)" }}>
      <div className="__block-container">
        <Center>
          <Heading textAlign="center" as="h1" fontWeight={900} style={{ padding: "105px 0" }}>
            The solution is to digitize processes and do away with manual management
          </Heading>
        </Center>
        <SimpleGrid marginBottom="105px" spacing={10} minChildWidth={300}>
          {blocksData.map((data) => (
            <GridItem key={data.id}>
              <div className="d-flex flex-column">
                <div className="d-flex align-center">
                  {/*boxSize="70px"*/}
                  <Image marginRight="16px" src={`/images/index_page/block_4/${data.id}.svg`} />
                  <Heading as="h2" fontSize="20px">
                    {data.title}
                  </Heading>
                </div>
                <Text marginTop="16px">{data.description}</Text>
              </div>
            </GridItem>
          ))}
        </SimpleGrid>
      </div>
    </div>
  );
}
