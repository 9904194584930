import React from "react";
import { Flex, Heading, Image } from "@chakra-ui/react";
import "./IndexPageBlock1.scss";

export function IndexPageBlock1() {
  return (
    <div className="indexPageBlock1 __block-wrapper">
      <Flex alignItems="center" className=" __block-container">
        <Image
          boxSize={window.location.pathname.includes("home") ? undefined : undefined}
          htmlWidth="140px"
          src={window.location.pathname.includes("home") ? "/images/tonnus_logo.png" : "/images/VC_logo.png"}
        />
        <Heading as="h5" fontSize="16px" className="pl-4">
          Tonnus is a 24/7 automated operations director
        </Heading>
      </Flex>
    </div>
  );
}
