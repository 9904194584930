import React, { useEffect, useRef, useState } from "react";
import { IndexPageBuyDialogView } from "./IndexPageBuyDialogView";
import { TBuyDialog } from "../../../../pages/IndexPage";
import axios from "axios";
import { useToast } from "@chakra-ui/react";

interface IIndexPageBuyDialog {
  isOpen: boolean;
  type: TBuyDialog;

  onClose: () => void;
}

export interface IIndexPageBuyDialogState {
  name: string;
  phoneNumber: string;
  email: string;
  extraEmployees: boolean;
  extraEmployeesAmount: number;
  premiumImplementationProgram: boolean;
  totalCost: number;
}

export function IndexPageBuyDialog(props: IIndexPageBuyDialog) {
  const toast = useToast();
  const requestUrl = "https://ck-visotsky.ru/tonnus/order.php";

  const initialState: IIndexPageBuyDialogState = {
    name: "",
    phoneNumber: "",
    email: "",
    extraEmployees: false,
    extraEmployeesAmount: 1,
    premiumImplementationProgram: false,
    totalCost: 0,
  };
  const [state, setState] = useState<IIndexPageBuyDialogState>(initialState);
  const stateCopy = useRef<IIndexPageBuyDialogState>(initialState);
  const setStateSync = (v: IIndexPageBuyDialogState) => {
    setState(v);
    stateCopy.current = v;
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleClose = () => {
    if (isLoading) return;
    props.onClose();
    setStateSync(initialState);
    setIsLoading(false);
    setIsSuccess(false);
  };

  const getQueryParams = () => {
    return Object.fromEntries(new URLSearchParams(window.location.search));
  };

  const handleSubmitForm = async () => {
    const formData = {
      email: stateCopy.current.email,
      phone: stateCopy.current.phoneNumber,
      tarif: (props.type ?? "").toLowerCase(),
      cost: stateCopy.current.totalCost,
      ...getUserNameFromState(stateCopy.current.name),
      ...getQueryParams(),
    };
    await sendRequestToServer(formData);
  };

  const sendRequestToServer = async (formData: any) => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
    };
    // utm_source, medium, campaign, term, content
    setIsLoading(true);
    axios
      .postForm(requestUrl, formData, { headers })
      .then((res) => {
        setIsSuccess(true);
      })
      .catch(function (error) {
        toast({
          title: "Something went wrong",
          description: "Make sure everything is ok and try again",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const getUserNameFromState = (name: string) => {
    const n = name.trim().replace(/\s\s+/g, " ");
    const nArr = n.split(" ");
    if (nArr.length == 1) {
      return {
        first_name: nArr[0],
      };
    } else if (nArr.length == 2) {
      return {
        first_name: nArr[0],
        last_name: nArr[1],
      };
    } else if (nArr.length == 3) {
      let nCopy = [...nArr];
      return {
        first_name: nCopy[0] + "_" + nCopy[1],
        last_name: nCopy.pop(),
      };
    } else if (nArr.length > 3) {
      let nCopy = [...nArr];
      return {
        first_name: nCopy.shift(),
        last_name: nCopy.join("_"),
      };
    }
  };

  const getProgramCost = (): number => {
    if (props.type == "standard") return 2490 + 290;
    if (props.type == "premium") return 10490 + 290;
    if (props.type == "vip") return 49490;
    return 0;
  };

  useEffect(() => {
    if (!props.isOpen) return;
    let cost = 0;
    if (stateCopy.current.extraEmployees) {
      cost += stateCopy.current.extraEmployeesAmount * 29;
    } else {
      //
    }
    cost += getProgramCost();
    setState({ ...stateCopy.current, totalCost: cost });
  }, [props.isOpen, stateCopy.current.extraEmployees, stateCopy.current.extraEmployeesAmount]);

  return (
    <IndexPageBuyDialogView
      isOpen={props.isOpen}
      state={state}
      isLoading={isLoading}
      isSuccess={isSuccess}
      setState={setStateSync}
      type={props.type}
      onClose={handleClose}
      onSubmitForm={handleSubmitForm}
    />
  );
}
