import React from "react";
import { Center, Heading, Image, List, Text } from "@chakra-ui/react";
import "./IndexPageBlock7.scss";
import { FiAlertCircle, FiCheck } from "react-icons/fi";

const blocksData = [
  {
    id: 1,
    title: "Regular business",
    items: [
      {
        id: 1,
        text: "The owner has to repeat tasks to staff all the time",
      },
      { id: 2, text: "The owner ensures that all tasks are completed" },
      { id: 3, text: "The owner has to train every new employee himself" },
      {
        id: 4,
        text: "Most of the owner's time is taken up by controlling tasks",
      },
    ],
  },
  {
    id: 2,
    title: "Business with automated operation management",
    items: [
      {
        id: 1,
        text: "Repetitive tasks are automated and reach employees on time",
      },
      {
        id: 2,
        text: "Task Driver gets each task done without distracting the supervisor",
      },
      {
        id: 3,
        text: "New employee automatically receives all necessary tasks that do not need to be explained to them",
      },
      {
        id: 4,
        text: "Control automation frees up time and attention for company development",
      },
    ],
  },
];

export function IndexPageBlock7() {
  return (
    <div className="indexPageBlock7 __block-wrapper">
      <Center>
        <Heading
          as="h4"
          fontSize="32px"
          textAlign="center"
          color="white"
          style={{ padding: "105px 0px" }}
          maxWidth="800px"
        >
          How is a business with automated operations management different from a conventional one?
        </Heading>
      </Center>
      <Center>
        <div className="__cards-wrapper">
          {blocksData.map((data, index) => (
            <div key={data.id} className="__card">
              <Image src={`/images/index_page/block_7/${data.id}.png`} />
              <Heading textAlign="center" className="my-15" as="h4" fontSize="22px" color="white">
                {data.title}
              </Heading>
              <List color="white">
                {data.items.map((item) => (
                  <span style={{ display: "flex", alignItems: "center" }} className="my-3" key={item.id}>
                    <span className="mr-4">
                      {index == 0 ? (
                        <FiAlertCircle color="red" fontSize="32px" />
                      ) : (
                        <FiCheck color="var(--color-primary)" fontSize="32px" />
                      )}
                    </span>
                    <Text>{item.text}</Text>
                  </span>
                ))}
              </List>
            </div>
          ))}
        </div>
      </Center>
    </div>
  );
}
