import React from "react";
import "./IndexPageBlock9.scss";
import { Button, Center, Heading, Text } from "@chakra-ui/react";
import { TBuyDialog } from "../../../../pages/IndexPage";
import { FiCheck, FiX } from "react-icons/fi";

interface IIndexPageBlock9 {
  onBuyClick: (type: TBuyDialog) => void;
}

const plansOptionsData = [
  { id: 1, text: "Duration of implementation and support" },
  { id: 2, text: "3 group workshops with a consultant" },
  { id: 3, text: "3 team implementation briefs" },
  { id: 4, text: "4 group Q&A sessions" },
  { id: 5, text: "Consultant email support" },
  { id: 6, text: "Tonus Club Telegram chat" },
  { id: 7, text: "Tonus Club Workshops and Webinars" },
  { id: 8, text: "1 workshop with a consultant about regulations implementation" },
  { id: 9, text: "2 personal sessions with a Tonnus consultant" },
  { id: 10, text: "6-days Org chart individual training" },
  { id: 11, text: "Business Modeling individual training" },
  { id: 12, text: "Consultant develops org chart for you" },
  { id: 13, text: "Tonnus training for you and your managers" },
  { id: 14, text: "Transferring ALL routines of your business to Tonnus" },
  { id: 15, text: "Development of regulations for you to train employees" },
  { id: 16, text: "Inspecting the use of the Tonnus system and developing a corrective action program" },
];

const plansData = [
  {
    id: 1,
    name: "Standard",
    cssClassName: "__plan-standard",
    availableOptions: [1, 2, 3, 4, 5, 6, 7],
    regularPrice: "$5,000",
    offerPrice: "$2,490",
    additionalText: [{ planOptionId: 1, text: "6 weeks" }],
  },
  {
    id: 2,
    name: "Premium",
    cssClassName: "__plan-premium",
    availableOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    regularPrice: "$21,500",
    offerPrice: "$10,490",
    additionalText: [{ planOptionId: 1, text: "3 months" }],
  },
  {
    id: 3,
    name: "VIP",
    cssClassName: "__plan-vip",
    availableOptions: [1, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    regularPrice: "from\n$70,000",
    offerPrice: "from\n$49,490",
    additionalText: [{ planOptionId: 1, text: "from\n5 months" }],
  },
];

export function IndexPageBlock9(props: IIndexPageBlock9) {
  return (
    <div className="indexPageBlock9 __block-wrapper">
      <div id="indexPageBlockToScroll" className="__block-container">
        <div style={{ padding: "105px 0" }}>
          <Center>
            <Heading as="h1" textAlign="center" fontWeight={900}>
              Implementation Program Price
            </Heading>
          </Center>
          <Center>
            <Heading as="h3" fontSize="20px" fontWeight={900} style={{ margin: "32px 0" }}>
              (Required with all plans)
            </Heading>
          </Center>
        </div>

        <div className="__table">
          <div className="__options-list">
            <div className="__row" />
            {plansOptionsData.map((data) => (
              <div className="__row" key={data.id}>
                <Text>{data.text}</Text>
              </div>
            ))}
            <div className="__row __regular-price">
              <span style={{ fontSize: "20px" }}>Regular Price</span>
            </div>
            <div className="__row __offer-price">
              <span style={{ fontSize: "20px" }}>Special Offer</span>
            </div>
            <div className="__row" style={{ background: "white" }} />
          </div>

          <div className="__plans-list">
            {[...Array(plansData.length)].map((_, i) => (
              <div key={i} className={`__plan-wrapper ${plansData[i].cssClassName ?? ""}`}>
                <div className="__row __header">{plansData[i].name}</div>
                {plansOptionsData.map((data) => (
                  <div
                    className={`__row ${
                      plansData[i].availableOptions.find((o) => o == data.id) != null ? "" : "__m_hidden"
                    }`}
                    key={data.id}
                  >
                    <span className="__m_visible">{plansOptionsData.find((o) => o.id == data.id)?.text ?? ""}</span>
                    {plansData[i].additionalText.find((a) => a.planOptionId == data.id) != null ? (
                      <span style={{ textAlign: "center", whiteSpace: "pre" }}>
                        {plansData[i].additionalText.find((a) => a.planOptionId == data.id)?.text}
                      </span>
                    ) : plansData[i].availableOptions.find((o) => o == data.id) != null ? (
                      <FiCheck
                        fontSize="40px"
                        strokeWidth="3px"
                        color="black"
                        style={{ background: "var(--color-primary)", borderRadius: "50%", padding: "6px" }}
                      />
                    ) : (
                      <FiX
                        fontSize="40px"
                        strokeWidth="3px"
                        color="white"
                        style={{ background: "red", borderRadius: "50%", padding: "6px" }}
                      />
                    )}
                  </div>
                ))}
                <div className="__row __regular-price">
                  <span className="__m_visible">Regular Price</span>
                  <span>{plansData[i].regularPrice}</span>
                </div>
                <div className="__row __offer-price">
                  <span className="__m_visible">Special Offer</span>
                  <span>{plansData[i].offerPrice}</span>
                </div>
                <div className="__row" style={{ background: "white", padding: "0 8px" }}>
                  <Button
                    onClick={() => props.onBuyClick(plansData[i]?.name?.toLowerCase() as TBuyDialog)}
                    colorScheme="red"
                    fontSize="20px"
                  >
                    BUY
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
